import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function EmployeeRetirementFundSelfDeductionInquiryForm() {
  const [rate, setRate] = useState(0);
  const navigate = useNavigate();

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">勞工退休金制度自提率徵詢表</h1>
        </div>
      </div>
      <div className='p-6 flex w-full'>
        <div className='flex-1'>
          <label className="text-gray-400">員工姓名</label>
          <div>王小明</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">身分證號</label>
          <div>A123456789</div>
        </div>
        <div className='flex-1'>
          <label className="text-gray-400">民國出生年月日</label>
          <div>80/1/1</div>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div>
          <p>勞工得在每月提撥級距6%範圍內，自願提繳退休金；</p>
          <p>其自願提繳之退休金，不計入提繳年度薪資所得課稅。</p>
          <p>請填寫數字0~6</p>
          <p>若不提撥請填寫0</p>
          <p>
            請填寫欲自提的提撥率:
            <input 
              className='ml-2 mr-2 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
              type="number" 
              min="0" 
              max="6" 
              value={rate} 
              onChange={(e) => setRate(e.target.value)} 
            /> 
            %
          </p>
        </div>
        <div className="overflow-x-auto">
          <div className="text-right mt-4">
            <button
              onClick={() => navigate('/recruiter')}
              className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeRetirementFundSelfDeductionInquiryForm;
