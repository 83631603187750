import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function SetUpUploadFiles() {
  const [formData, setFormData] = useState({
    memo: '',
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/interviews`);
  }

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">報到管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">設定錄取者應上傳文件</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <table className="w-full border-collapse border border-gray-300 mt-4">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"><input type="checkbox" /></th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">全選</th>
            </tr>
          </thead>   
          <tbody>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">銀行存摺</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">國民身份證/居留證</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">最高學歷證明</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">退伍證明</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">前一公司之薪資證明</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">前一公司之離職證明書</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">前一公司之勞健保轉出單</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">新進員工體格檢查報告一份</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">專業認證資格證明文件</td>
            </tr>
            <tr>
              <td className="border px-4 py-2"><input type="checkbox" /></td>
              <td className="border px-4 py-2">其他國家考試或技能檢定合格證明文件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        <button type="button" onClick={() => navigate(`/admissions`)} className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300">
          返回
        </button>
      </div>
    </div>
  );
}

export default SetUpUploadFiles;
