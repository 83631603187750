import React from 'react';
import { useNavigate } from 'react-router-dom';

function PersonalDataCategories() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">個人資料保護法之特定目的及個人資料之類別 </h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6 max-w-6xl">
        <table className='text-center border'>
          <thead>
            <tr>
              <th className='w-1/12 border p-2'>代號</th>
              <th className='w-1/3 border p-2'>修正特定目的項目</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border p-2'>○○一</td>
              <td className='border p-2'>人身保險</td>
            </tr>
            <tr>
              <td className='border p-2'>○○二</td>
              <td className='border p-2'>人事管理(包含甄選、離職及所屬員工基本資訊、現職、學經歷、考試分發、終身學習訓練進修、考績獎懲、銓審、薪資待遇、差勤、福利措施、褫奪公權、特殊查核或其他人事措施)</td>
            </tr>
            <tr>
              <td className='border p-2'>○○三</td>
              <td className='border p-2'>入出國及移民</td>
            </tr>
            <tr>
              <td className='border p-2'>○○四</td>
              <td className='border p-2'>土地行政</td>
            </tr>
            <tr>
              <td className='border p-2'>○○五</td>
              <td className='border p-2'>工程技術服務業之管理</td>
            </tr>
            <tr>
              <td className='border p-2'>○○六</td>
              <td className='border p-2'>工業行政</td>
            </tr>
            <tr>
              <td className='border p-2'>○○七</td>
              <td className='border p-2'>不動產服務</td>
            </tr>
            <tr>
              <td className='border p-2'>○○八</td>
              <td className='border p-2'>中小企業及其他產業之輔導</td>
            </tr>
            <tr>
              <td className='border p-2'>○○九</td>
              <td className='border p-2'>中央銀行監理業務</td>
            </tr>
            <tr>
              <td className='border p-2'>○一○</td>
              <td className='border p-2'>公立與私立慈善機構管理</td>
            </tr>
            <tr>
              <td className='border p-2'>○一一</td>
              <td className='border p-2'>公共造產業務</td>
            </tr>
            <tr>
              <td className='border p-2'>○一二</td>
              <td className='border p-2'>公共衛生或傳染病防治</td>
            </tr>
            <tr>
              <td className='border p-2'>○一三</td>
              <td className='border p-2'>公共關係</td>
            </tr>
            <tr>
              <td className='border p-2'>○一四</td>
              <td className='border p-2'>公職人員財產申報、利益衝突迴避及政治獻金業務</td>
            </tr>
            <tr>
              <td className='border p-2'>○一五</td>
              <td className='border p-2'>戶政</td>
            </tr>
            <tr>
              <td className='border p-2'>○一六</td>
              <td className='border p-2'>文化行政</td>
            </tr>
            <tr>
              <td className='border p-2'>○一七</td>
              <td className='border p-2'>文化資產管理</td>
            </tr>
            <tr>
              <td className='border p-2'>○一八</td>
              <td className='border p-2'>水利、農田水利行政</td>
            </tr>
            <tr>
              <td className='border p-2'>○一九</td>
              <td className='border p-2'>火災預防與控制、消防行政</td>
            </tr>
            <tr>
              <td className='border p-2'>○二○</td>
              <td className='border p-2'>代理與仲介業務</td>
            </tr>
          </tbody>
        </table>
        <div className="text-right mt-4">
          <button
            onClick={() => navigate('/recruiter/use-personal-data-agreement')}
            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            返回
          </button>
        </div>
      </div>
    </div>
  );
}

export default PersonalDataCategories;
