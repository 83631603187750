import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getById, create, update } from '../../services/api';

function LetterTemplateForm() {
  const [formData, setFormData] = useState({
    taxId: '',
    name: '',
    address: '',
    bank: '',
    reportLocation: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTemplate = useCallback(async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await getById('/api/ehrm/v1/mailtemplatre', id);
      setFormData(response);
      console.log('API 完整響應:', response);
    } catch (error) {
      console.error('獲取信件模板失敗:', error);
    } finally {
      setLoading(false);
    }
  }, [getById]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      update('/api/ehrm/v1/mailtemplatre', id, formData);
    } else {
      create('/api/ehrm/v1/mailtemplatre', formData);
    }
    console.log('保存的數據:', formData);
    navigate('/letter-templates');
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">信件模板{id ? '編輯' : '新增'}</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">模板名稱</label>
            <input
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <textarea
              type="text"
              name="content"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              value={formData.content}
              onChange={handleInputChange}
              rows="10"
              style={{ minHeight: '200px' }}
            />
          </div>
          <div className="flex justify-center space-x-2">
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
            <button type="button" onClick={() => navigate('/letter-templates')} className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              返回
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LetterTemplateForm;