import React, { useState, useEffect, useCallback } from 'react';
import { deleteEvaluationcriteria, getEvaluationcriteria, createEvaluationcriteria, updateEvaluationcriteria } from '../../services/api';

function InterviewEvaluation() {
  const [category, setCategory] = useState('');
  const [position, setPosition] = useState('');
  const [editEvaluation, setEditEvaluation] = useState('');
  const [role, setRole] = useState('');
  const [newRole, setNewRole] = useState('');
  const [jobCategory, setJobCategory] = useState('');
  const [newJobCategory, setNewJobCategory] = useState('');
  const [evaluations, setEvaluations] = useState([]);
  const [evaluationItem, setEvaluationItem] = useState('');
  const [order, setOrder] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const handleSearch = () => {
    console.log('查詢:', { role, jobCategory });
  };

  const fetchEvaluationcriteria = useCallback(async (category, position) => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await getEvaluationcriteria(category, position);
      console.log(response);
      setEvaluations(response.items);
      console.log('API 完整響應:', response);
    } catch (error) {
      console.error('獲取評估標準失敗:', error);
    } finally {
      setLoading(false);
    }
  }, [getEvaluationcriteria, category, position]);

  useEffect(() => {
    fetchEvaluationcriteria(category, position);
  }, [fetchEvaluationcriteria, category, position]);

  const handleAdd = () => {
    if (newRole && newJobCategory && evaluationItem && order) {
      setEvaluations([...evaluations, { categoryName: newRole, positionName: newJobCategory, criteria: evaluationItem, order }]);
      setNewRole('');
      setNewJobCategory('');
      setEvaluationItem('');
      setOrder('');
      createEvaluationcriteria({ categoryName: category, positionName: position, criteria: evaluationItem, order: order });
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedEvaluations = [...evaluations];
      updatedEvaluations[editIndex] = editEvaluation;
      setEvaluations(updatedEvaluations);
      setEditIndex(null);
      updateEvaluationcriteria(editEvaluation.id, { categoryName: editEvaluation.categoryName, positionName: editEvaluation.positionName, criteria: editEvaluation.criteria, order: editEvaluation.order });
    } else {
      setEvaluations([...evaluations, editEvaluation]);
      console.log(2)
    }
    setEditEvaluation('');
  };

  const handleAddCancel = () => {
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditEvaluation(evaluations[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個來源嗎？')) {
      const updatedEvaluations = evaluations.filter((_, i) => i !== index);
      setEvaluations(updatedEvaluations);
      deleteEvaluationcriteria(evaluations[index].id);
    }
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試評核項目列表</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mb-6">
          <div>
            <select
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">面試者角色</option>
              <option value="HR">HR</option>
              <option value="前端開發">前端開發</option>
              <option value="後端開發">後端開發</option>
              <option value="全端開發">全端開發</option>
            </select>
          </div>

          <div>
            <select
              className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            >
              <option value="">職務類別</option>
              <option value="工程師">工程師</option>
              <option value="設計師">設計師</option>
              <option value="業務">業務</option>
            </select>
          </div>

          <div>
            <button
              onClick={handleSearch}
              className="px-4 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              查詢
            </button>
          </div>
        </div>
        
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">面試者角色</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">職務類別</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">評測項目</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">順序</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2">
                  <select
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                  >
                    <option value="">請選擇</option>
                    <option value="HR">HR</option>
                    <option value="前端開發">前端開發</option>
                    <option value="後端開發">後端開發</option>
                    <option value="全端開發">全端開發</option>
                  </select>
                </td>
                <td className="px-4 py-2">
                  <select
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={newJobCategory}
                    onChange={(e) => setNewJobCategory(e.target.value)}
                  >
                    <option value="">請選擇</option>
                    <option value="工程師">工程師</option>
                    <option value="設計師">設計師</option>
                    <option value="業務">業務</option>
                  </select>
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={evaluationItem}
                    onChange={(e) => setEvaluationItem(e.target.value)}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                  />
                </td>
                <td className="px-4 py-2">
                  <button
                    onClick={handleAdd}
                    className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {evaluations.map((evaluation, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editEvaluation.categoryName}
                        onChange={(e) => setEditEvaluation({...editEvaluation, categoryName: e.target.value})}
                      >
                        <option value="">請選擇</option>
                        <option value="HR">HR</option>
                        <option value="前端開發">前端開發</option>
                        <option value="後端開發">後端開發</option>
                        <option value="全端開發">全端開發</option>
                      </select>
                    ) : (
                      evaluation.categoryName
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editEvaluation.positionName}
                        onChange={(e) => setEditEvaluation({...editEvaluation, positionName: e.target.value})}
                      >
                        <option value="">請選擇</option>
                        <option value="工程師">工程師</option>
                        <option value="設計師">設計師</option>
                        <option value="經理">經理</option>
                        <option value="業務">業務</option>
                      </select>
                    ) : (
                      evaluation.positionName
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editEvaluation.criteria}
                        onChange={(e) => setEditEvaluation({...editEvaluation, criteria: e.target.value})}
                      />
                    ) : (
                      evaluation.criteria
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editEvaluation.order}
                        onChange={(e) => setEditEvaluation({...editEvaluation, order: e.target.value})}
                      />
                    ) : (
                      evaluation.order
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <div>
                        <button
                          onClick={handleEditSave}
                          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          儲存
                        </button>
                        <button
                          onClick={handleAddCancel}
                          className="ml-2 px-3 py-1 rounded-md bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          取消
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          onClick={() => handleEdit(index)}
                          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
                        >
                          編輯
                        </button>
                        <button
                          onClick={() => handleDelete(index)}
                          className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          刪除
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InterviewEvaluation;
