import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { get } from '../../services/api';

function LetterTemplates() {
  const [newTemplate, setNewTemplate] = useState('');
  const [templates, setTemplates] = useState([
    { id: 1, name: '錄取通知模板', isEditing: false, originalName: '錄取通知模板' }
  ]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTemplates = useCallback(async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await get('/api/ehrm/v1/mailtemplatres');
      setTemplates(response);
      console.log('API 完整響應:', response);
    } catch (error) {
      console.error('獲取信件模板失敗:', error);
    } finally {
      setLoading(false);
    }
  }, [get]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const handleAdd = () => {
    if (newTemplate.trim()) {
      setTemplates([...templates, { id: Date.now(), name: newTemplate, isEditing: false, originalName: newTemplate }]);
      setNewTemplate('');
    }
  };

  const handleEdit = (id, newName) => {
    setTemplates(templates.map(template =>
      template.id === id ? { ...template, name: newName } : template
    ));
  };

  const handleSave = (id) => {
    setTemplates(templates.map(template =>
      template.id === id ? { ...template, isEditing: false, originalName: template.name } : template
    ));
  };

  const handleCancel = (id) => {
    setTemplates(templates.map(template =>
      template.id === id ? { ...template, isEditing: false, name: template.originalName } : template
    ));
  };

  const handleDelete = (id) => {
    const templateToDelete = templates.find(template => template.id === id);
    if (window.confirm(`確定要刪除「${templateToDelete.name}」嗎？`)) {
      setTemplates(templates.filter(template => template.id !== id));
    }
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">信件模板</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="text-right">
          <Link to="/letter-templates/new" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
            新增信件模板
          </Link>
        </div>
        <table className="w-full border-collapse border border-gray-300 mt-4">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">模板列表</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
            </tr>
          </thead>        
          <tbody>
            {templates.map(template => (
              <tr key={template.id}>
                <td className="border px-4 py-2">
                  {template.isEditing ? (
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={template.name}
                      onChange={(e) => handleEdit(template.id, e.target.value)}
                    />
                  ) : (
                    template.name
                  )}
                </td>
                <td className="border px-4 py-2">
                  {template.isEditing ? (
                    <>
                      <button
                        onClick={() => handleSave(template.id)}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                      >
                        保存
                      </button>
                      <button
                        onClick={() => handleCancel(template.id)}
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                      >
                        取消
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => navigate(`/letter-templates/edit/${template.id}`)} 
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                      >
                        編輯
                      </button>
                      <button
                        onClick={() => handleDelete(template.id)}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      >
                        刪除
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LetterTemplates;
