import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function DocUploadSchedule() {
  const [formData, setFormData] = useState({
    memo: '',
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/interviews`);
  }

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">報到管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">文件上傳進度</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <table className="w-full border-collapse border border-gray-300 mt-4">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">完成資料</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">最終編輯時間</th>
            </tr>
          </thead>   
          <tbody>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/upload-bank-book" className="text-blue-500 hover:underline">
                  銀行存摺
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/upload-id" className="text-blue-500 hover:underline">
                  國民身份證/居留證
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/upload-education" className="text-blue-500 hover:underline">
                  最高學歷證明
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/upload-discharge" className="text-blue-500 hover:underline">
                  退伍證明
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/upload-resignation" className="text-blue-500 hover:underline">
                  前一公司離職相關文件
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/physical-examination-report" className="text-blue-500 hover:underline">
                  新進員工體格檢查報告一份
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/upload-professional-certificate" className="text-blue-500 hover:underline">
                  專業認證資格證明文件
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">
                <Link to="/onboards/doc-upload-schedule/upload-other-certificate" className="text-blue-500 hover:underline">
                  其他國家考試或技能檢定合格證明文件
                </Link>
              </td>
              <td className="border px-4 py-2">2024-09-06</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        <button type="button" onClick={() => navigate(`/onboards`)} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          結案
        </button>
        <button type="button" onClick={() => navigate(`/onboards`)} className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
          返回
        </button>
      </div>
    </div>
  );
}

export default DocUploadSchedule;
