import React from 'react';
import { useNavigate } from 'react-router-dom';

function FileUpload() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">上傳文件列表</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="overflow-x-auto">
          <div className='flex flex-col gap-2'>
            <ul>
              <li><a href="#">銀行存摺</a></li>
              <li><a href="#">國民身份證/居留證</a></li>
              <li><a href="#">最高學歷證明</a></li>
              <li><a href="#">退伍證明</a></li>
              <li><a href="#">前一公司離職相關文件上傳</a></li>
              <li><a href="#">新進員工體格檢查報告一份</a></li>
              <li><a href="#">專業認證資格證明文件</a></li>
              <li><a href="#">其他國家考試或技能檢定合格證明文件</a></li>
            </ul>
          </div>
          <div className="text-right mt-4">
            <button
              onClick={() => navigate('/recruiter')}
              className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              返回
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
