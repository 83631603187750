import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function InterviewMemo() {
  const [formData, setFormData] = useState({
    memo: '',
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/previous-manager?interviewId=${id}`);
  }

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試者填寫應徵者自我期許</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-lg font-medium text-gray-700">自我期許</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="10"
              style={{ minHeight: '200px' }}
              placeholder='請寫下您對此項職務的瞭解或曾有的工作經驗，以及您對自己未來的生涯規劃'
            />
          </div>
          <div className="flex justify-center space-x-2">
            <button type="reset" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InterviewMemo;
