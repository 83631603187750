import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TiDelete } from 'react-icons/ti';

function UploadedBankbook() {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const navigate = useNavigate();
  const FileUpload1 = (e) => {
    e.target.files[0] && setFile1(URL.createObjectURL(e.target.files[0]));
  };

  const FileUpload2 = (e) => {
    e.target.files[0] && setFile2(URL.createObjectURL(e.target.files[0]));
  };

  const DeleteFile1 = () => {
    setFile1(null);
  };

  const DeleteFile2 = () => {
    setFile2(null);
  };

  return (
    <div className="flex flex-col items-center justify-center max-w-6xl mx-auto">
      <div className="w-full text-left">
        <div className="flex justify-between items-center mb-6">
          <div>
            <span className="text-gray-400"></span>
            <h1 className="text-2xl font-semibold text-gray-800">上傳存摺正反面影本</h1>
          </div>
        </div>
      </div>
      <div className='w-full bg-white shadow-md rounded-lg p-6'>
        <div className="flex">
          <div className="w-1/2">
            <div className="text-lg font-bold mb-4">正面：</div>
            {file1 ? (
              <div className="image-wrapper mr-8">
                <TiDelete onClick={DeleteFile1} className="float-right text-2xl text-red-500" />
                <img src={file1} alt="Uploaded" className="image" />
              </div>
            ) : (
              <div className="flex justify-center items-center mr-8 bg-gray-200" style={{ width: '500px', height: '340px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
                <label>
                  <input
                    type="file"
                    onChange={FileUpload1}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                  <span className="text-center border-2 rounded-3xl  p-3 bg-blue-500 border-blue-500 text-white">照片上傳</span>
                </label>
              </div>
            )}
          </div>
        <div className="w-1/2">
          <div className="text-lg font-bold mb-4">反面：</div>
          {file2 ? (
            <div className="image-wrapper mr-8">
              <TiDelete onClick={DeleteFile2} className="float-right text-2xl text-red-500" />
              <img src={file2} alt="Uploaded" className="image" />
            </div>
          ) : (
            <div className="flex justify-center items-center bg-gray-200" style={{ width: '500px', height: '340px', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
              <label>
                <input
                  type="file"
                  onChange={FileUpload2}
                  style={{ display: "none" }}
                  accept="image/*"
                />
                <span className="text-center border-2 rounded-3xl  p-3 bg-blue-500 border-blue-500 text-white">照片上傳</span>
              </label>
            </div>
          )}
          </div>
        </div>
        <div className="flex justify-center space-x-2 mt-4">
          <button type="button" onClick={() => navigate(`/recruiter/doc-upload`)} className="px-4 py-2 border rounded-md bg-blue-500 text-white rounded-md hover:bg-blue-600">
            確認上傳
          </button>
          <button type="button" onClick={() => navigate(`/recruiter/doc-upload`)} className="px-4 py-2 border rounded-md text-gray-600 bg-gray-200 hover:bg-gray-100">
            返回
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadedBankbook;
