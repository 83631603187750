import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function InterviewMemo() {
  const [formData, setFormData] = useState({
    previousManagers: [],
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];
  const [newLocation, setNewLocation] = useState({ name: '', company: '', relationship: '', title: '', phone: '' });
  const [editingIndex, setEditingIndex] = useState(-1);

  useEffect(() => {
    console.log('formData:', formData);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewLocationChange = (e) => {
    const { name, value } = e.target;
    setNewLocation(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/family-status?interviewId=${id}`);
  }

  const handleAddLocation = () => {
    if (newLocation.city && newLocation.address) {
      setFormData(prev => ({
        ...prev,
        previousManagers: [...prev.previousManagers, newLocation]
      }));
      setNewLocation({ city: '', address: '' });
    }
  };

  const handleLocationChange = (index, field, value) => {
    const updatedLocations = formData.onBoardAddress.map((loc, i) => 
      i === index ? { ...loc, [field]: value } : loc
    );
    setFormData({ ...formData, previousManagers: updatedLocations });
  };

  const handleEditLocation = (index) => {
    setEditingIndex(index);
  };

  const handleSaveLocation = () => {
    setEditingIndex(-1);
  };

  const handleDeleteLocation = (index) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        const updatedLocations = formData.onBoardAddress.filter((_, i) => i !== index);
        setFormData({ ...formData, onBoardAddress: updatedLocations });
      } catch (error) {
        console.error('刪除公司失敗:', error);
      }
    }
  };

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">面試者填寫應徵者前公司主管</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-lg font-medium text-gray-700">前公司主管(供資歷查核使用)</label>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100 text-center">
                  <th className="p-2 font-medium text-gray-700 w-1/6">姓名</th>
                  <th className="p-2 font-medium text-gray-700 w-1/6">公司名稱</th>
                  <th className="p-2 font-medium text-gray-700 w-1/6">關係</th>
                  <th className="p-2 font-medium text-gray-700 w-1/6">職稱</th>
                  <th className="p-2 font-medium text-gray-700 w-1/6">電話</th>
                  <th className="p-2 font-medium text-gray-700 w-1/6">管理</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">
                    <input
                      type="text"
                      name="city"
                      value={newLocation.city}
                      onChange={handleNewLocationChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="city"
                      value={newLocation.city}
                      onChange={handleNewLocationChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="city"
                      value={newLocation.city}
                      onChange={handleNewLocationChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="city"
                      value={newLocation.city}
                      onChange={handleNewLocationChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      name="address"
                      value={newLocation.address}
                      onChange={handleNewLocationChange}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md"
                    />
                  </td>
                  <td className="p-2">
                    <button
                      type="button"
                      onClick={handleAddLocation}
                      className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                    >
                      新增
                    </button>
                  </td>
                </tr>
                {formData.previousManagers.map((location, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={location.city}
                          onChange={(e) => handleLocationChange(index, 'city', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        location.city
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <input
                          type="text"
                          value={location.address}
                          onChange={(e) => handleLocationChange(index, 'address', e.target.value)}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        />
                      ) : (
                        location.address
                      )}
                    </td>
                    <td className="p-2">
                      {editingIndex === index ? (
                        <button
                          type="button"
                          onClick={handleSaveLocation}
                          className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300"
                        >
                          保存
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleEditLocation(index)}
                          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                        >
                          編輯
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => handleDeleteLocation(index)}
                        className="ml-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
                      >
                        刪除
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center space-x-2">
            <button type="reset" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InterviewMemo;
