import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import Layout from './components/General/Layout';
import Login from './components/General/Login';
import Home from './components/General/Home';

// 系統管理
import Companies from './components/System/Companies';
import CompanyForm from './components/System/CompanyForm';
import InterviewEvaluation from './components/System/InterviewEvaluation';
import ApplicantSources from './components/System/ApplicantSources';
import GroupInsurancePlan from './components/System/GroupInsurancePlan';
import LetterTemplates from './components/System/LetterTemplates';
import LetterTemplateForm from './components/System/LetterTemplateForm';

// 招募管理
import Interviews from './components/Recruit/Interviews';
import InterviewForm from './components/Recruit/InterviewForm';
import InterviewUploadForm from './components/Recruit/InterviewUploadForm';
import Meetings from './components/Recruit/Meetings';
import Meeting from './components/Recruit/Meeting';
import MeetingForm from './components/Recruit/MeetingForm';
import HRScoreForm from './components/Recruit/HRScoreForm';
import ManagerScoreForm from './components/Recruit/ManagerScoreForm';
import ReferenceChecks from './components/Recruit/ReferenceChecks';
import ReferenceCheckForm from './components/Recruit/ReferenceCheckForm';
import InterviewResult from './components/Recruit/InterviewResult';

// 人事應徵系統
import LayoutApplicant from './components/ApplicationInfo/Layout';
import ApplicationInfo from './components/ApplicationInfo/ApplicationInfo';
import ProfileForm from './components/ApplicationInfo/ProfileForm';
import Education from './components/ApplicationInfo/EducationForm';
import Experience from './components/ApplicationInfo/ExperienceForm';
import SkillsForm from './components/ApplicationInfo/SkillsForm';
import InterviewMemo from './components/ApplicationInfo/InterviewMemoForm';
import OtherInfo from './components/ApplicationInfo/OtherInfoForm';
import Goals from './components/ApplicationInfo/GoalsForm';
import PreviousManager from './components/ApplicationInfo/PreviousManagerForm';
import Declaration from './components/ApplicationInfo/DeclarationForm';
import Questions from './components/ApplicationInfo/QuestionsForm';
import FamilyStatus from './components/ApplicationInfo/FamilyStatusForm';

// 錄取管理
import Admissions from './components/Admission/Admissions';
import NotifyForm from './components/Admission/NotifyForm';
import Profile from './components/Admission/Profile';
import HRM from './components/Admission/HRM';
import SetUpUploadFiles from './components/Admission/SetUpUploadFiles';

// 報到管理
import Onboards from './components/Onboard/Onboards';
import OnboardSchedule from './components/Onboard/OnboardSchedule';
import LayoutOnboardRecruiter from './components/Onboard/Layout';
import DocUploadSchedule from './components/Onboard/DocUploadSchedule';

// 
import InformationSecurityPromotionInstructions from './components/Recruiter/InformationSecurityPromotionInstructions';
import UploadedBankbook from './components/Recruiter/UploadBankbook';
import UploadID from './components/Recruiter/UploadID';
import UploadEducation from './components/Recruiter/UploadEducation';
import UploadDischarge from './components/Recruiter/UploadDischarge';
import UploadResignation from './components/Recruiter/UploadResignation';
import PhysicalExaminationReport from './components/Recruiter/PhysicalExaminationReport';
import UploadProfessionalCertificate from './components/Recruiter/UploadProfessionalCertificate';
import UploadOtherCertificate from './components/Recruiter/UploadOtherCertificate';
import DocUpload from './components/Recruiter/DocUpload';
import LayoutRecruiter from './components/Recruiter/Layout';
import Recruiter from './components/Recruiter/Recruiter';

// 錄取人員資料
import OnboardRecruiter from './components/Onboard/OnboardRecruiter';
import ServiceAgreement from './components/Recruiter/ServiceAgreement';
import EmployeeFamilyHealthInsuranceEnrollmentRecord from './components/Recruiter/EmployeeFamilyHealthInsuranceEnrollmentRecord';
import EmployeeRetirementFundSelfDeductionInquiryForm from './components/Recruiter/EmployeeRetirementFundSelfDeductionInquiryForm';
import EngineerCertificateDetails from './components/Recruiter/EngineerCertificateDetails';
import SelfIntroduction from './components/Recruiter/SelfIntroduction';
import SetEnglishName from './components/Recruiter/SetEnglishName';
import FileUpload from './components/Recruiter/FileUpload';
import PersonalDataAgreement from './components/Recruiter/PersonalDataAgreement';
import PhysicalHealthDataCollectionAgreement from './components/Recruiter/PhysicalHealthDataCollectionAgreement';
import EmployeeComputerSoftwareUseSelfRegulationAgreement from './components/Recruiter/EmployeeComputerSoftwareUseSelfRegulationAgreement';
import TaxExemptionSpouseForm from './components/Recruiter/TaxExemptionSpouseForm';
import TaxExemptionChildrenForm from './components/Recruiter/TaxExemptionChildrenForm';
import TaxExemptionSiblingsForm from './components/Recruiter/TaxExemptionSiblingsForm';
import TaxExemptionOtherRelativesForm from './components/Recruiter/TaxExemptionOtherRelativesForm';
import TaxExemptionSummaryForm from './components/Recruiter/TaxExemptionSummaryForm';
import TaxExemptionImmediateRelativesForm from './components/Recruiter/TaxExemptionImmediateRelativesForm';
import CompanyAuthorizedSoftwareList from './components/Recruiter/CompanyAuthorizedSoftwareList';
import UsePersonalDataAgreement from './components/Recruiter/UsePersonalDataAgreement';
import PersonalDataCategories from './components/Recruiter/PersonalDataCategories';

// 會員管理
import Users from './components/User/User';
import Roles from './components/User/Roles';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={
          isLoggedIn ? <Navigate to="/" /> : <Login onLogin={handleLogin} />
        } />
        <Route path="/" element={!isLoggedIn ? <Layout /> : <Navigate to="/login" />}>

          // 系統管理 
          <Route index element={<Home />} />
          <Route path="interview-evaluation" element={<InterviewEvaluation />} />
          <Route path="applicant-sources" element={<ApplicantSources />} />
          <Route path="companies" element={<Companies />} />
          <Route path="companies/new" element={<CompanyForm />} />
          <Route path="companies/edit/:id" element={<CompanyForm />} />
          <Route path="group-insurance-plan" element={<GroupInsurancePlan />} />
          <Route path="letter-templates" element={<LetterTemplates />} />
          <Route path="letter-templates/new" element={<LetterTemplateForm />} />
          <Route path="letter-templates/edit/:id" element={<LetterTemplateForm />} />

          // 招募管理
          <Route path="interviews" element={<Interviews />} />
          <Route path="interviews/new" element={<InterviewForm />} />
          <Route path="interviews/edit/:id" element={<InterviewForm />} />
          <Route path="interviews/upload/:id" element={<InterviewUploadForm />} />
          <Route path="interviews/reference-checks/:id" element={<ReferenceChecks />} />
          <Route path="interviews/reference-checks/new" element={<ReferenceCheckForm />} />
          <Route path="interviews/reference-checks/edit/:id" element={<ReferenceCheckForm />} />
          <Route path="interviews/result/:id" element={<InterviewResult />} />
          <Route path="meetings" element={<Meetings />} />
          <Route path="meetings/:id" element={<Meeting />} />
          <Route path="meetings/new" element={<MeetingForm />} />
          <Route path="meetings/edit/:id" element={<MeetingForm />} />
          <Route path="meetings/hr-score/:id" element={<HRScoreForm />} />
          <Route path="meetings/manager-score/:id" element={<ManagerScoreForm />} />

          // 錄取管理 
          <Route path="admissions" element={<Admissions />} />
          <Route path="admission/notify/:id" element={<NotifyForm />} />
          <Route path="admission/profile/:id" element={<Profile />} />
          <Route path="admission/hrm/:id" element={<HRM />} />
          <Route path="admission/set-up-upload-files/:id" element={<SetUpUploadFiles />} />
          
          // 報到管理
          <Route path="onboards" element={<Onboards />} />
          <Route path="onboards/onboard-schedule/:id" element={<OnboardSchedule />} />
          <Route path="onboards/doc-upload-schedule/:id" element={<DocUploadSchedule />} />

          // 會員管理
          <Route path="users" element={<Users />} />
          <Route path="roles" element={<Roles />} />
        </Route>

        // 報到管理：錄取者畫面
        <Route path="/" element={<LayoutOnboardRecruiter />}>
          <Route path="onboard-recruiter" element={<OnboardRecruiter />} />
          <Route path="onboard-recruiter/service-agreement" element={<ServiceAgreement />} />
          <Route path="onboard-recruiter/use-personal-data-agreement" element={<UsePersonalDataAgreement />} />
          <Route path="onboard-recruiter/personal-data-agreement" element={<PersonalDataAgreement />} />
          <Route path="onboard-recruiter/employee-computer-software-use-self-regulation-agreement" element={<EmployeeComputerSoftwareUseSelfRegulationAgreement />} />
          <Route path="onboard-recruiter/physical-health-data-collection-agreement" element={<PhysicalHealthDataCollectionAgreement />} />
          </Route>

        // 人事應徵系統
        <Route path="/" element={<LayoutApplicant />}>
          <Route path="application-info" element={<ApplicationInfo />} />
          <Route path="application-info/profile" element={<ProfileForm />} />
          <Route path="application-info/education" element={<Education />} />
          <Route path="application-info/experience" element={<Experience />} />
          <Route path="application-info/skills" element={<SkillsForm />} />
          <Route path="application-info/interview-memo" element={<InterviewMemo />} />
          <Route path="application-info/other-info" element={<OtherInfo />} />
          <Route path="application-info/goals" element={<Goals />} />
          <Route path="application-info/previous-manager" element={<PreviousManager />} />
          <Route path="application-info/declaration" element={<Declaration />} />
          <Route path="application-info/questions" element={<Questions />} />
          <Route path="application-info/family-status" element={<FamilyStatus />} />
        </Route>

        // 錄取管理：錄取者畫面
        <Route path="/" element={<LayoutRecruiter />}>
          <Route path="recruiter" element={<Recruiter />} />
          <Route path="recruiter/employee-family-health-insurance-enrollment-record" element={<EmployeeFamilyHealthInsuranceEnrollmentRecord />} />
          <Route path="recruiter/employee-retirement-fund-self-deduction-inquiry-form" element={<EmployeeRetirementFundSelfDeductionInquiryForm />} />
          <Route path="recruiter/engineer-certificate-details" element={<EngineerCertificateDetails />} />
          <Route path="recruiter/self-introduction" element={<SelfIntroduction />} />
          <Route path="recruiter/set-english-name" element={<SetEnglishName />} />
          <Route path="recruiter/file-upload" element={<FileUpload />} />
          <Route path="recruiter/tax-exemption-spouse-form" element={<TaxExemptionSpouseForm />} />
          <Route path="recruiter/tax-exemption-children-form" element={<TaxExemptionChildrenForm />} />
          <Route path="recruiter/tax-exemption-siblings-form" element={<TaxExemptionSiblingsForm />} />
          <Route path="recruiter/tax-exemption-other-relatives-form" element={<TaxExemptionOtherRelativesForm />} />
          <Route path="recruiter/tax-exemption-summary-form" element={<TaxExemptionSummaryForm />} />
          <Route path="recruiter/tax-exemption-immediate-relatives-form" element={<TaxExemptionImmediateRelativesForm />} />
          <Route path="recruiter/information-security-promotion-instructions" element={<InformationSecurityPromotionInstructions />} />
          <Route path="recruiter/doc-upload" element={<DocUpload />} />
          <Route path="recruiter/doc-upload/upload-bank-book" element={<UploadedBankbook />} />
          <Route path="recruiter/doc-upload/upload-id" element={<UploadID />} />
          <Route path="recruiter/doc-upload/upload-education" element={<UploadEducation />} />
          <Route path="recruiter/doc-upload/upload-discharge" element={<UploadDischarge />} />
          <Route path="recruiter/doc-upload/upload-resignation" element={<UploadResignation />} />
          <Route path="recruiter/doc-upload/physical-examination-report" element={<PhysicalExaminationReport />} />
          <Route path="recruiter/doc-upload/upload-professional-certificate" element={<UploadProfessionalCertificate />} />
          <Route path="recruiter/doc-upload/upload-other-certificate" element={<UploadOtherCertificate />} />
          <Route path="recruiter/company-authorized-software-list" element={<CompanyAuthorizedSoftwareList />} />
          <Route path="recruiter/personal-data-categories" element={<PersonalDataCategories />} />
        </Route>

      </Routes>
    </Router>
  );
}

export default App;
