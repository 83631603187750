import React, { useState, useRef, useEffect } from 'react';
import { Menu, User, LogOut } from 'lucide-react';

function Header({ toggleSidebar, isSidebarOpen }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="py-2 px-4 bg-white shadow-md">
      <div className="flex items-center justify-between">
        <button
          onClick={toggleSidebar}
          className="mr-4 p-2 rounded-md hover:bg-gray-200 focus:outline-none"
        >
          <Menu className="w-6 h-6" />
        </button>
        <div className={`text-lg font-bold ${isSidebarOpen ? 'hidden' : ''}`}>
          零壹科技錄取管理
        </div>
        <div className="relative ml-4" ref={dropdownRef}>
          <button
            className="flex items-center text-sm focus:outline-none"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span className='font-medium text-gray-400'>錄用者</span>
            <span className="ml-2 font-medium text-gray-700">王小明</span>
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-36 bg-white rounded-md shadow-lg py-1 z-10">
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none"
                onClick={() => {
                  // 處理個人資料按鈕點擊
                  setIsDropdownOpen(false);
                }}
              >
                <User className="inline-block w-4 h-4 mr-2" />
                個人資料
              </button>
              <button
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none"
                onClick={() => {
                  // 處理登出按鈕點擊
                  setIsDropdownOpen(false);
                }}
              >
                <LogOut className="inline-block w-4 h-4 mr-2" />
                登出
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
