import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TiDelete } from 'react-icons/ti';

function UploadOtherCertificate() {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState(null);
  const [newFileName, setNewFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleNewFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewFile(file);
      setNewFileName(file.name);
    }
  };

  const handleFileNameChange = (e) => {
    setNewFileName(e.target.value);
  };

  const handleFileUpload = (e) => {
    e.preventDefault(); 
    if (newFile && newFileName) {
      setIsUploading(true);
      setTimeout(() => {
        setFiles((prevFiles) => [
          ...prevFiles,
          { ...newFile, name: newFileName },
        ]);
        setNewFile(null); 
        setNewFileName(""); 
        setIsUploading(false);
      }, 1000);
    }
  };

  const deleteFile = (fileToDelete) => {
    setFiles(files.filter((file) => file !== fileToDelete));
  };

  const ButtonSaveReturnStyle = {
    padding: "4px 24px",
    border: "1px solid #000",
    borderRadius: "8px",
    color: "#fff",
    background: "rgb(108, 108, 248)",
    cursor: "pointer",
  };

  return (
    <div className="flex flex-col items-center justify-center max-w-6xl mx-auto">
      <div className="w-full text-left">
        <div className="flex justify-between items-center mb-6">
          <div>
            <span className="text-gray-400"></span>
            <h1 className="text-2xl font-semibold text-gray-800">其他國家考試或技能檢定合格證明文件上傳</h1>
          </div>
        </div>
      </div>
      <div className="w-full bg-white shadow-md rounded-lg p-6">
        <div
          className="w-full"
          style={{
          display: "flex",
          flexDirection: "column",
          padding: "40px 80px",
        }}
        >
          <div>
            {newFile && (
              <form
                onSubmit={handleFileUpload} 
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                <div>
                  <input
                    type="text"
                    value={newFileName}
                    onChange={handleFileNameChange}
                    placeholder="Filename"
                    style={{
                      padding: "8px",
                      outline: "none",
                      border: "1px solid #000",
                    }}
                  />
                </div>
                <button
                  type="submit" 
                  disabled={isUploading}
                  className="mb-4 px-4 py-2 border rounded-md bg-blue-500 text-white hover:bg-blue-600"
                >
                  {isUploading ? "上傳檔案中..." : "上傳檔案"}
                </button>
              </form>
            )}
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "0",
              }}
            >
              {files.map((file, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    listStyle: "none",
                  }}
                >
                  {file.name}
                  <button onClick={() => {
                    if (window.confirm('確定要刪除這個檔案嗎？')) {
                      deleteFile(file);
                    }
                  }} 
                  className="px-4 py-2 border rounded-md bg-red-500 text-white hover:bg-red-600"
                >
                  刪除
                </button>
                </li>
              ))}
            </ul>
            <button
              className="mt-4 px-4 py-2 border rounded-md bg-blue-500 text-white hover:bg-blue-600"
              onClick={() => document.getElementById("file-input").click()}
            >
              新增認證
            </button>
            <input
              id="file-input"
              type="file"
              onChange={handleNewFileSelect}
              style={{ display: "none" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
          </div>
        </div>
        <div className="flex justify-center space-x-2 mt-4">
          <button type="button" onClick={() => navigate(`/recruiter/doc-upload`)} className="px-4 py-2 border rounded-md bg-blue-500 text-white rounded-md hover:bg-blue-600">
            確認上傳
          </button>
          <button type="button" onClick={() => navigate(`/recruiter/doc-upload`)} className="px-4 py-2 border rounded-md text-gray-600 bg-gray-200 hover:bg-gray-100">
            返回
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadOtherCertificate;
