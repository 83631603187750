import React, { useState, useEffect } from 'react';
import Header from './Header';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';

function Layout() {
  const id = useLocation().search.split('interviewId=')[1];
  const logo = require('../../assets/zerone-logo.png');
  const isDesktop = window.innerWidth > 1024;
  const [isSidebarOpen, setIsSidebarOpen] = useState(isDesktop);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // 新增狀態來控制各部分的展開/收合
  const [expandedSections, setExpandedSections] = useState({
    systemManagement: true,
    recruitmentManagement: true,
    hireManagement: true,
    onboardingManagement: true,
    memberManagement: true
  });

  useEffect(() => {
    const checkScreenSize = () => {
      const isSmall = window.innerWidth < 1024;
      setIsSmallScreen(isSmall);
      if (!isSmall) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isSmallScreen) {
      setIsSidebarOpen(false);
    }
  };

  const isActive = (path) => {
    console.log(path)
    return location.pathname === path;
  };

  const menuItemClass = (path) => `
    w-28
    ${isActive(path) 
      ? 'text-blue-600' 
      : 'text-gray-700'}
  `;

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      {/* 左側選單 */}
      <nav className={`
        ${isSidebarOpen ? 'translate-x-0 lg:relative lg:transform-none' : '-translate-x-full'}
        bg-white shadow-lg transition-transform duration-300 ease-in-out
        fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto
      `}>
        <div className="p-4">
          <h1 onClick={() => handleNavigation('/application-info')} className="text-center font-bold text-gray-800 cursor-pointer">零壹科技人事應徵系統
          </h1>
        </div>
        <ul className="ml-12">
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/profile?interviewId=${id}`} className={menuItemClass('/application-info/profile')}>
                基本資料
              </Link>
              <span className='text-gray-400'></span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/education?interviewId=${id}`} className={menuItemClass('/application-info/education')}>
                教育程度
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/experience?interviewId=${id}`} className={menuItemClass('/application-info/experience')}>
                工作經歷
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/skills?interviewId=${id}`} className={menuItemClass('/application-info/skills')}>
                專長/技能
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/other-info?interviewId=${id}`} className={menuItemClass('/application-info/other-info')}>
                其他說明
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/goals?interviewId=${id}`} className={menuItemClass('/application-info/goals')}>
                自我期許
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/previous-manager?interviewId=${id}`} className={menuItemClass('/application-info/previous-manager')}>
                前公司主管
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/family-status?interviewId=${id}`} className={menuItemClass('/application-info/family-status')}>
                家庭狀況
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/questions?interviewId=${id}`} className={menuItemClass('/application-info/questions')}>
                問題填寫
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/declaration?interviewId=${id}`} className={menuItemClass('/application-info/declaration')}>
                填寫聲明
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/interview-memo?interviewId=${id}`} className={menuItemClass('/application-info/interview-memo')}>
                面試Memo
              </Link>
              <span className='text-gray-400'>待完成</span>
            </div>
          </li>
        </ul>
      </nav>

      {/* 遮罩層 */}
      {isSidebarOpen && isSmallScreen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden" 
          onClick={toggleSidebar}
        ></div>
      )}

      {/* 主要內容區域 */}
      <main className={`flex-1 flex flex-col overflow-hidden transition-all duration-300`}>
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        <div className="flex-1 overflow-auto p-6">
          <Outlet />
        </div>
        <div className="flex justify-between items-end p-4 px-10">
          <div className='text-gray-400 text-sm font-bold'>
            COMMIT TO EXCELLENCE
          </div>
          <div>
            <img src={logo} alt="零壹科技eHRM" width={100} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Layout;
