import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function InterviewResult() {
  const [interviewResult, setInterviewResult] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const id = useLocation().pathname.split('/').pop();

  const fetchInterviewResult = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/api/comm/v1/system/Corporations`, {
        // params: {
        //   page: page,
        //   limit: companiesPerPage,
        //   search: search
        // }
      });
      console.log('API 完整響應:', response.data);

      if (response.data && Array.isArray(response.data.items)) {
        setInterviewResult(response.data.items);
      } else {
        console.error('API 響應結構不符合預期:', response.data);
        setInterviewResult([]);
        setError('獲取數據時出現問題');
      }
    } catch (error) {
      console.error('獲取失敗:', error);
      setError('獲取列表時發生錯誤');
      setInterviewResult([]);
    } finally {
      setLoading(false);
    }
  });

  const calculateTotalScore = () => {
    let totalScore = 0;
    for (let i = 1; i <= 6; i++) {
      let score = document.getElementById(`score${i}`).value;
      totalScore += parseInt(score) || 0;
    }
    setTotalScore(totalScore); // 更新总分状态
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">面談管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">HR評分</h1>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className='flex w-3/4'>
          <div className='flex-1'>
            <label className="text-gray-400">應徵人員姓名</label>
            <div>王小明</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵公司</label>
            <div>零壹科技</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵部門</label>
            <div>資訊中心</div>
          </div>
          <div className='flex-1'>
            <label className="text-gray-400">應徵職務</label>
            <div>工程師</div>
          </div>
        </div>
        <div className="flex mt-4">
          <div className="overflow-x-auto w-1/2">
            <h3>評價級別</h3>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-center">
                  <th className="p-3 font-semibold text-sm text-gray-600">評分項目</th>
                  <th className="p-3 font-semibold text-sm text-gray-600">評分</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-3 text-sm text-gray-700">1.外在形象（儀容儀表）</td>
                  <td className="p-3 text-sm text-gray-700">
                    <select id="score1" onChange={calculateTotalScore}>
                      <option value="">評分</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="p-3 text-sm text-gray-700">2.性格與公司文化的匹配度</td>
                  <td className="p-3 text-sm text-gray-700">
                    <select id="score2" onChange={calculateTotalScore}>
                      <option value="">評分</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="p-3 text-sm text-gray-700">3.積極度及工作熱忱</td>
                  <td className="p-3 text-sm text-gray-700">
                    <select id="score3" onChange={calculateTotalScore}>
                      <option value="">評分</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="p-3 text-sm text-gray-700">4.表達的條理性</td>
                  <td className="p-3 text-sm text-gray-700">
                    <select id="score4" onChange={calculateTotalScore}>
                      <option value="">評分</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="p-3 text-sm text-gray-700">5.人際互動能力</td>
                  <td className="p-3 text-sm text-gray-700">
                    <select id="score5" onChange={calculateTotalScore}>
                      <option value="">評分</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="p-3 text-sm text-gray-700">6.學習反應能力</td>
                  <td className="p-3 text-sm text-gray-700">
                    <select id="score6" onChange={calculateTotalScore}>
                      <option value="">評分</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="p-3 text-sm text-gray-700">總計得分</td>
                  <td className="p-3 text-sm text-gray-700">
                    {totalScore}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="overflow-x-auto w-1/2 ml-4">
            <h3>評價級別-初試主管</h3>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-center">
                  <th className="p-3 font-semibold text-sm text-gray-600">評項項目</th>
                  <th className="p-3 font-semibold text-sm text-gray-600">評分</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td className="p-3 text-sm text-gray-700">1.專業技能匹配度</td>
                <td className="p-3 text-sm text-gray-700">4(良好)</td>
              </tr>
              <tr>
                <td className="p-3 text-sm text-gray-700">2.工作經歷匹配度</td>
                <td className="p-3 text-sm text-gray-700">4(良好)</td>
              </tr>
              <tr>
                <td className="p-3 text-sm text-gray-700">3.性格與團隊的匹配度</td>
                <td className="p-3 text-sm text-gray-700">4(良好)</td>
              </tr>
              <tr>
                <td className="p-3 text-sm text-gray-700">4.業務特質與企圖心</td>
                <td className="p-3 text-sm text-gray-700">4(良好)</td>
              </tr>
              <tr>
                <td className="p-3 text-sm text-gray-700">5.抗壓力</td>
                <td className="p-3 text-sm text-gray-700">3(中等)</td>
              </tr>
              <tr className="text-center">
                <td className="p-3 text-sm text-gray-700">總計得分</td>
                <td className="p-3 text-sm text-gray-700">19</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 mr-4">
            <div className="mt-2">
              <label htmlFor="interviewComments" className="block text-sm font-medium text-gray-700">面試評語</label>
              <textarea
                id="interviewComments"
                name="interviewComments"
                rows={2}
                className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </div>
            <div className="flex justify-center space-x-3 mt-3">
              <button type="button" onClick={() => navigate(`/meetings?interviewId=${id}`)} className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
                返回
              </button>
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
                送出
              </button>
            </div>
          </div>
          <div className="w-1/2 ml-4">
            <div className="mt-2">
              <label htmlFor="evaluationComments" className="block text-sm font-medium text-gray-700">評語</label>
              xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterviewResult;
