import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Questions() {
  const [formData, setFormData] = useState({
    memo: '',
    question9: ''
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/declaration?interviewId=${id}`);
  }

  const reset = () => {
    setFormData({question9: ''});
  }

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">問題填寫</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <h3 className="text-2xl font-semibold text-gray-800">問題填寫</h3>
          <div>
            <label className="block text-lg font-medium text-gray-700">一、為何想應徵本公司職缺？</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">二、離開前家公司的理由？</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">三、在以往的工作中最喜歡與最不喜歡的工作類型？</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">四、請說明您對新工作的期待？</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">五、請問您目前求職狀況，已面試哪些公司？是否其他公司通知錄取？</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">六、承上題，目前考慮的公司中，有機會及興趣加入的，請說明考慮原因？</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">七、前一份工作主管領導風格？對於這樣的領導風格/管理作風喜歡或不喜歡的原因為何？</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">八、您認為自己的優點及缺點？（請列舉各三個）</label>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="mt-4">
            <label className="block text-lg font-medium text-gray-700">九、為何我們要錄取您？您有哪些特點和別人不同？</label>
            <textarea
              type="text"
              name="question9"
              value={formData.question9}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="2"
              style={{ minHeight: '20px' }}
            />
          </div>
          <div className="flex justify-center space-x-2">
            <button type="button" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100" onClick={() => window.confirm('確定要清除所有資料嗎？') ? reset() : ''}>
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Questions;
