import React from 'react';
import { useNavigate } from 'react-router-dom';

function InformationSecurityPromotionInstructions() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">資訊安全宣導須知</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div>
          1	資訊安全政策		
          <p>
            (1)	資訊安全之本質大致可歸為以下 3 類：	
            <p>
              機密性－Confidentiality：	<br/>
              使資訊不可用或不揭露給未經授權之個人、個體或過程的性質	
            </p>
            <p>
              完整性－Integrity：	<br/>
              保護資產的準確度(accuracy)和完全性(completeness)的性質	
            </p>
            <p>
              可用性－Availability：	<br/>
              經授權個體因應需求之可存取及可使用的性質	
            </p>	
          </p>
          <p>
            (2)	資安政策之目標	<br/>
            確保公司重要資料之機密性、隱密性及防止非法使用。	<br/>
            確保資訊系統及設備之可用性與安全性。	<br/>
            確保資訊業務運作之有效性及持續性。	
          </p>
          <p>
            2	資訊安全事件通報及處理		
            <p>
              (1)	 資安事件分級：	
            </p>
            <p>
              (一)	重大災害/事故：屬重大災害/事故(服務中斷無法於目標回復時間內恢復之事件)
            </p>
            <p>
              (二)	一般安全事故：屬一般安全事故(造成服務中斷不致超過目標回復時間之事件)
            </p>
            <p>
              (三)	其他資訊安全事件：無法歸類之其他資訊安全事件
            </p>
          </p>
          <p>
            (2)	發現資訊安全意外事故或可疑之安全弱點，請向直屬主管回報，由主管依循資安事件通報程序向資訊處回報處理。	
          </p>
          <p>
            3	資訊安全檢視
          </p>
          <p>
            (1)	公司電腦只允許安裝合法授權軟體，切勿使用盜版軟體，若需額外安裝非標準化之合法授權軟體，請填寫『IT服務申請單』。	
          </p>
          <p>
            (2)	公司資料只允許應用在公司電腦上，禁止分享網路或其它儲存裝置。	
          </p>
          <p>
            (3)	AD個人密碼每90天更新。請遵守密碼複雜性原則變更，且勿將密碼放至公開處提供他人使用。	
          </p>
          <p>
            (4)	公司電腦皆已安裝防毒軟體，每日定期更新病毒碼，每週五PM12:00全機掃描。	
          </p>
          <p>
            (5)	電腦設備應保持乾燥清潔、避免飲食，尤其勿將液體放置電腦邊。	
          </p>
          <p>
            (6)	請勿在網路上勾選「記住密碼」選項 。	
          </p>
          <p>
            (7)	離開座位前應將螢幕鎖定或執行「登出帳號」動作。	
          </p>
          <p>
            (8)	避免在公共電腦中輸入敏感性高的資訊，使用完畢也請清除瀏覽紀錄及務必登出。	
          </p>
          <p>
            (9)	在網路上發言前，三思而後發佈，不隨意轉寄未經證實的網路流言信件等。	
          </p>
          <p>
            (10)	不透過任何通訊軟體傳遞個人資料，或重要的公司機密資料。	
          </p>
          <p>
            (11)	不點選可疑email 所提供之超連結，凡事求證後才作業，可減少網路釣魚與網路上被詐騙的機會。	
          </p>
          <p>
            (12)	傳送機敏性電子資料檔案時，應以加密處理，密碼應以其他途徑通知，避免與原資料檔案一同傳送。	
          </p>
          <p>
            (13)	儲存媒體報廢時，必需將內存資料清除之，若含有機敏性資料之儲存媒體，還必需增加物理性破壞，以確保資料不當外洩。	
          </p>
          <p>
            (14)	公司資料請儲存於『D:\OneDrive - 零壹科技股份有限公司』內，也請留意OneDrive服務是否正常，才能同步雲端備份。	
          </p>
        </div>
        <div className="text-right mt-4">
          <button
            onClick={() => navigate('/recruiter/employee-computer-software-use-self-regulation-agreement')}
          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
            返回
          </button>
        </div>
      </div>
    </div>
  );
}

export default InformationSecurityPromotionInstructions;