import React from 'react';
import { useNavigate } from 'react-router-dom';

function CompanyAuthorizedSoftwareList() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">公司合法授權軟體清單 </h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6 max-w-6xl">
        <table className='text-center border'>
          <thead>
            <tr>
              <th className='w-1/12 border p-2'>編號</th>
              <th className='w-1/3 border p-2'>項目</th>
              <th className='w-1/3 border p-2'>功能</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border p-2'>1</td>
              <td className='border p-2'>Windows 10</td>
              <td className='border p-2'>OS</td>
            </tr>
            <tr>
              <td className='border p-2'>2</td>
              <td className='border p-2'>Adobe Acrobat Reader</td>
              <td className='border p-2'>PDF閱讀軟體</td>
            </tr>
            <tr>
              <td className='border p-2'>3</td>
              <td className='border p-2'>7ZIP</td>
              <td className='border p-2'>壓縮軟體</td>
            </tr>
            <tr>
              <td className='border p-2'>4</td>
              <td className='border p-2'>Chrome\Firefox</td>
              <td className='border p-2'>瀏覽器</td>
            </tr>
            <tr>
              <td className='border p-2'>5</td>
              <td className='border p-2'>Trend ApexOne</td>
              <td className='border p-2'>防毒軟體</td>
            </tr>
            <tr>
              <td className='border p-2'>6</td>
              <td className='border p-2'>LINE\Webex</td>
              <td className='border p-2'>通訊軟體</td>
            </tr>
            <tr>
              <td className='border p-2'>7</td>
              <td className='border p-2'>M365</td>
              <td className='border p-2'>文書作業系統</td>
            </tr>
          </tbody>
        </table>
        <div className="text-right mt-4">
          <button
            onClick={() => navigate('/recruiter/employee-computer-software-use-self-regulation-agreement')}
          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
            返回
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyAuthorizedSoftwareList;
