import React, { useState } from 'react';

function GroupInsurancePlan() {
  const [newPlan, setNewPlan] = useState('');
  const [editPlan, setEditPlan] = useState('');
  const [plans, setPlans] = useState([
    '死亡150萬，實支實付3萬，住院日額1,500'
  ]);
  const [editIndex, setEditIndex] = useState(null);

  const handleAdd = () => {
    if (newPlan) {
      setPlans([...plans, newPlan]);
      setNewPlan('');
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedPlans = [...plans];
      updatedPlans[editIndex] = editPlan;
      setPlans(updatedPlans);
      setEditIndex(null);
    } else {
      setPlans([...plans, editPlan]);
    }
    setEditPlan('');
  };

  const handleAddCancel = () => {
    setNewPlan('');
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditPlan(plans[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個方案嗎？')) {
      const updatedPlans = plans.filter((_, i) => i !== index);
      setPlans(updatedPlans);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">團保方案管理</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">方案</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2">
                  <input
                    type="text"
                    className="w-full px-2 py-1 border border-gray-300 
                    rounded-md shadow-sm focus:outline-none 
                    focus:ring-2 focus:ring-blue-500 
                    focus:border-blue-500"
                    value={newPlan}
                    onChange={(e) => setNewPlan(e.target.value)}
                    placeholder="輸入新的團保方案"
                  />
                </td>
                <td className="px-4 py-2">
                  <button
                    onClick={handleAdd}
                    className="px-3 py-1 bg-blue-500 text-white 
                    rounded-md hover:bg-blue-600 focus:outline-none 
                    focus:ring-2 focus:ring-blue-500 
                    focus:ring-offset-2"
                  >
                    新增
                  </button>
                </td>
              </tr>
              {plans.map((plan, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <input
                        type="text"
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editPlan}
                        onChange={(e) => setEditPlan(e.target.value)}
                      />
                    ) : (
                      plan
                    )}
                  </td>
                  <td className="px-4 py-2">
                    {editIndex === index ? (
                      <div>
                        <button
                          onClick={handleEditSave}
                          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          儲存
                        </button>
                        <button
                          onClick={handleAddCancel}
                          className="ml-2 px-3 py-1 rounded-md bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          取消
                        </button>
                      </div>
                    ) : (
                      <>
                        <button
                          onClick={() => handleEdit(index)}
                          className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
                        >
                          編輯
                        </button>
                        <button
                          onClick={() => handleDelete(index)}
                          className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          刪除
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default GroupInsurancePlan;
