import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaxExemptionFormMenu from './TaxExemptionFormMenu';
import TaxExemptionFormIncomeRecipient from './TaxExemptionFormIncomeRecipient';

function TaxExemptionSpouseForm() {
  const [persons, setPersons] = useState([
    { id: 1, name: '劉小芬', birthDate: '83/08/01', idNumber: 'A123456789', address: '台北市信義區' }
  ]);
  const [newTemplate, setNewTemplate] = useState('');
  const [templates, setTemplates] = useState([
    { id: 1, name: '錄取通知模板', isEditing: false, originalName: '錄取通知模板' }
  ]);
  const navigate = useNavigate();

  const handleAdd = () => {
    if (newTemplate.trim()) {
      setTemplates([...templates, { id: Date.now(), name: newTemplate, isEditing: false, originalName: newTemplate }]);
      setNewTemplate('');
    }
  };

  const handleEdit = (id, newName) => {
    setTemplates(templates.map(template =>
      template.id === id ? { ...template, name: newName } : template
    ));
  };

  const handleSave = (id) => {
    setTemplates(templates.map(template =>
      template.id === id ? { ...template, isEditing: false, originalName: template.name } : template
    ));
  };

  const handleCancel = (id) => {
    setTemplates(templates.map(template =>
      template.id === id ? { ...template, isEditing: false, name: template.originalName } : template
    ));
  };

  const handleDelete = (id) => {
    const templateToDelete = templates.find(template => template.id === id);
    if (window.confirm(`確定要刪除「${templateToDelete.name}」嗎？`)) {
      setTemplates(templates.filter(template => template.id !== id));
    }
  };

  return (
    <div className="mx-auto max-w-6xl">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">員工薪資所得受領人免稅額申報表</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <TaxExemptionFormMenu activeTab="spouse" />
        <div>
          <TaxExemptionFormIncomeRecipient />
          <table className="w-full border-collapse border border-gray-300 mt-4">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">姓名</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">民國出生年月日</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">身分證字號統一編號</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">現在地址</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">編輯</th>
              </tr>
            </thead>        
            <tbody>
              {persons.map(person => (
                <tr key={person.id}>
                  <td className="border px-4 py-2">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={person.name}
                      onChange={(e) => handleEdit(person.id, e.target.value)}
                    />
                  </td>
                  <td className="border px-4 py-2">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={person.birthDate}
                      onChange={(e) => handleEdit(person.id, e.target.value)}
                    />
                  </td>
                  <td className="border px-4 py-2">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={person.idNumber}
                      onChange={(e) => handleEdit(person.id, e.target.value)}
                    />
                  </td>
                  <td className="border px-4 py-2">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={person.address}
                      onChange={(e) => handleEdit(person.id, e.target.value)}
                    />
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                    >
                      編輯
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        <button type="button" onClick={() => navigate(`/recruiter/tax-exemption-immediate-relatives-form`)} className="px-4 py-2 border rounded-md text-gray-600 bg-blue-500 text-white hover:bg-blue-600">
          下一步
        </button>
        <button type="button" onClick={() => navigate(`/onboards`)} className="px-4 py-2 border rounded-md text-gray-600 bg-blue-500 text-white hover:bg-blue-600">
          儲存並返回
        </button>
      </div>
    </div>
  );
}

export default TaxExemptionSpouseForm;
