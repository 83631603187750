import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function NotifyForm() {
  const [formData, setFormData] = useState({
    taxId: '',
    name: '',
    address: '',
    bank: '',
    reportLocation: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      // 如果有 id，則從 API 獲取公司數據
      // 這裡使用模擬數據
      const company = { id: 1, taxId: '12345678', name: '測試公司 A', englishName: 'Test Company A', address: '台北市 101', bank: '台灣銀行', reportLocation: '台北總部' };
      setFormData(company);
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // 這裡應該發送 API 請求來保存數據
    console.log('保存的數據:', formData);
    navigate('/companies');
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">錄取管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">錄取通知</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6 max-w-6xl">
        <div className='flex w-3/4'>
          <div className='flex-1'>
            <label className="text-gray-400">錄取人員姓名</label>
            <div>王小明</div>
            </div>
            <div className='flex-1'>
              <label className="text-gray-400">錄取公司</label>
              <div>零壹科技</div>
            </div>
            <div className='flex-1'>
              <label className="text-gray-400">錄取部門</label>
              <div>資訊中心</div>
            </div>
            <div className='flex-1'>
              <label className="text-gray-400">錄取職務</label>
            <div>工程師</div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className='mt-4'>
            <select name="letterTemplate" className="mt-1 block border border-gray-300 rounded-md shadow-sm p-2">
              <option value="1">錄取通知模板</option>
              <option value="2">模板二</option>
            </select>
          </div>
          <div>
            <textarea
              type="text"
              name="name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              rows="10"
              style={{ minHeight: '200px' }}
            />
          </div>
          <div className="flex justify-center space-x-2">
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              發送
            </button>
            <button type="button" onClick={() => navigate('/admissions')} className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NotifyForm;