import React from 'react';
import { useLocation, Link } from 'react-router-dom';

function ApplicationInfo() {
  const id = useLocation().search.split('interviewId=')[1];

  return (
    <div id="application-info">

    </div>
  );
}

export default ApplicationInfo;
