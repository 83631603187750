import React, {useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Experience() {
  const id = useLocation().search.split('interviewId=')[1];
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    taxIdNumber: '',
    chineseName: '',
    address: '',
    onBoardAddress: [],
  });
  const [newExperience, setNewExperience] = useState('');
  const [editExperience, setEditExperience] = useState('');
  const [experiences, setExperiences] = useState([{
    companyName: 'xxxx有限公司', 
    location: '台北市內湖區', 
    position: '軟體工程師', 
    monthlySalaries: '50,000', 
    yearlySalaries: '700,000', 
    startDate: '2021/07', 
    endDate: '2024/05',
    managerName: '王小明',
    workContent: '程式設計',
    leavingReason: '自願性',
    leavingReasonDesc: ''
  }]);
  const [editIndex, setEditIndex] = useState(null);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/skills?interviewId=${id}`);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleAdd = () => {
    if (newExperience.Experience && newExperience.school && newExperience.major && newExperience.graduationStatus && newExperience.startDate && newExperience.endDate) {
      setExperiences([...experiences, newExperience]);
      setNewExperience('');
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedExperiences = [...experiences];
      console.log(editExperience)
      updatedExperiences[editIndex] = editExperience;
      setExperiences([...experiences, updatedExperiences]);
      setEditIndex(null);
      console.log(1)
    } else {
      setExperiences([...experiences, editExperience]);
      console.log(2)
    }
    setEditExperience('');
  };

  const handleAddCancel = () => {
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditExperience(experiences[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個來源嗎？')) {
      const updatedExperiences = experiences.filter((_, i) => i !== index);
      setExperiences(updatedExperiences);
    }
  };

  const handleSave = () => {
    // 儲存邏輯
    // ... 儲存資料的代碼 ...

    // 儲存後導航到教育頁面
    navigate(`/application-info/expertise?interviewId=${id}`);
  };

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">工作經歷</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-300">
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-4 py-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">公司姓名</label>
                        <input
                          type="text"
                          name="taxIdNumber"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.taxIdNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">地點</label>
                        <input
                          type="text"
                          name="chineseName"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.chineseName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">職稱</label>
                        <input
                          type="text"
                          name="englishName"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.englishName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">月薪／年薪</label>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                        <span className='ml-1 mr-1'>/</span>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">起迄時間</label>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                        ~
                        <input
                          type="text"
                          name="phoneNumber"
                          className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">主管姓名</label>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">工作內容</label>
                        <input
                          type="text"
                          name="phoneNumber"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">離職原因</label>
                        <input
                          type="radio"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          className='mr-1'
                        />
                        自願性
                        <input
                          type="radio"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          className='mr-1 ml-3'
                        />
                        非自願性，原因
                        <input
                          type="text"
                          name="phoneNumber"
                          className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ml-3"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={handleAdd}
                      className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      新增
                    </button>
                  </td>
                </tr>
                {experiences.map((experience, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">公司姓名</label>
                          {editIndex === index ? (
                            <input
                              type="text"
                              name="companyName"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.companyName}
                              onChange={handleInputChange}
                            />
                          ) : (
                            experience.companyName
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">地點</label>
                          {editIndex === index ? (
                            <input
                              type="text"
                              name="chineseName"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.location}
                              onChange={handleInputChange}
                            />
                          ) : (
                            experience.location
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">職稱</label>
                          {editIndex === index ? (
                            <input
                              type="text"
                              name="englishName"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.englishName}
                              onChange={handleInputChange}
                            />
                          ) : (
                            experience.position
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">月薪／年薪</label>
                          {editIndex === index ? (
                            <input
                              type="text"
                              name="phoneNumber"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.phoneNumber}
                              onChange={handleInputChange}
                            />
                          ) : (
                            <div>
                              {experience.monthlySalaries} / {experience.yearlySalaries}
                            </div>
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">起迄時間</label>
                          {editIndex === index ? (
                            <input
                              type="text"
                              name="phoneNumber"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.phoneNumber}
                              onChange={handleInputChange}
                            />
                          ) : (
                            <div>
                              {experience.startDate} ~ {experience.endDate}
                            </div>
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">主管姓名</label>
                          {editIndex === index ? (
                            <input
                              type="text"
                              name="phoneNumber"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.phoneNumber}
                              onChange={handleInputChange}
                            />
                          ) : (
                            experience.managerName
                          )}
                        </div>
                        <div className="col-span-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">工作內容</label>
                          {editIndex === index ? (
                            <input
                              type="text"
                              name="phoneNumber"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              value={formData.phoneNumber}
                              onChange={handleInputChange}
                            />
                          ) : (
                            experience.workContent
                          )}
                        </div>
                        <div className="col-span-2">
                          <label className="block text-sm font-medium text-gray-700 mb-1">離職原因</label>
                          {editIndex === index ? (
                            <div>
                              <input
                                type="radio"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                className='mr-1'
                              />
                              自願性
                              <input
                                type="radio"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                className='mr-1 ml-3'
                              />
                              非自願性，原因
                              <input
                                type="text"
                                name="phoneNumber"
                                className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ml-3"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                              />
                            </div>
                          ) : (
                            experience.leavingReason
                            // experience.leavingReasonDesc
                          )}
                        </div>
                      </div>                    
                    </td>
                    <td className="px-4 py-2">
                      {editIndex === index ? (
                        <div>
                          <button
                            onClick={handleEditSave}
                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            儲存
                          </button>
                          <button
                            onClick={handleAddCancel}
                            className="ml-2 px-3 py-1 rounded-md bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            取消
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => handleEdit(index)}
                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
                          >
                            編輯
                          </button>
                          <button
                            onClick={() => handleDelete(index)}
                            className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          >
                            刪除
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex justify-center space-x-2">
            <button type="reset" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Experience;
