import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompanyById, createCompany, updateCompany } from '../../services/api';
import Autocomplete from '../General/Autocomplete';

function CompanyForm() {
  const [formData, setFormData] = useState({
    taxIdNumber: '',
    chineseName: '',
    address: '',
    // bank: '',
    // phone: '',
    // personInCharge: '',
    onBoardAddress: [],
  });
  const [newLocation, setNewLocation] = useState({ city: '', address: '' });
  const [editingIndex, setEditingIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  // 添加銀行列表狀態
  const [banks] = useState([
    '台灣銀行', '中國信託銀行', '國泰世華銀行', '玉山銀行', '台北富邦銀行'
  ]);

  useEffect(() => {
    if (id) {
      fetchCompanyData();
    }
  }, [id]);

  const fetchCompanyData = async () => {
    setLoading(true);
    try {
      const data = await getCompanyById(id);
      setFormData(data);
    } catch (error) {
      setError('獲取公司數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewLocationChange = (e) => {
    const { name, value } = e.target;
    setNewLocation(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (id) {
        await updateCompany(id, formData);
      } else {
        await createCompany(formData);
      }
      navigate('/companies');
    } catch (error) {
      setError('保存公司數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleAddLocation = () => {
    if (newLocation.city && newLocation.address) {
      setFormData(prev => ({
        ...prev,
        onBoardAddress: [...prev.onBoardAddress, newLocation]
      }));
      setNewLocation({ city: '', address: '' });
    }
  };

  const handleLocationChange = (index, field, value) => {
    const updatedLocations = formData.onBoardAddress.map((loc, i) => 
      i === index ? { ...loc, [field]: value } : loc
    );
    setFormData({ ...formData, onBoardAddress: updatedLocations });
  };

  const handleEditLocation = (index) => {
    setEditingIndex(index);
  };

  const handleSaveLocation = () => {
    setEditingIndex(-1);
  };

  const handleDeleteLocation = (index) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        const updatedLocations = formData.onBoardAddress.filter((_, i) => i !== index);
        setFormData({ ...formData, onBoardAddress: updatedLocations });
      } catch (error) {
        console.error('刪除公司失敗:', error);
      }
    }
  };

  // 添加處理銀行選擇的函數
  const handleBankSelect = (selectedBank) => {
    setFormData({ ...formData, bank: selectedBank });
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">系統管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">公司資料{id ? '編輯' : '新增'}</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-6">
          <h3 className="text-lg font-semibold text-gray-800 mb-4">基本資料</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">公司統編</label>
              <input
                type="text"
                name="taxIdNumber"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.taxIdNumber}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">公司名稱</label>
              <input
                type="text"
                name="chineseName"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.chineseName}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">公司英文名稱</label>
              <input
                type="text"
                name="englishName"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.englishName}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">公司電話</label>
              <input
                type="text"
                name="phoneNumber"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">負責人</label>
              <input
                type="text"
                name="companyDirector"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.companyDirector}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">公司登記地址</label>
              <input
                type="text"
                name="address"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">開戶銀行</label>
              <Autocomplete
                suggestions={banks}
                value={formData.bank}
                onChange={handleInputChange}
                onSelect={handleBankSelect}
                name="bank"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-4">報到地點</h3>
            <div className="bg-gray-50 p-4 rounded-md">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 text-center">
                    <th className="p-2 font-medium text-gray-700 w-1/4">地區</th>
                    <th className="p-2 font-medium text-gray-700 w-2/4">地點</th>
                    <th className="p-2 font-medium text-gray-700 w-1/4">管理</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-2">
                      <input
                        type="text"
                        name="city"
                        value={newLocation.city}
                        onChange={handleNewLocationChange}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        placeholder="新地區"
                      />
                    </td>
                    <td className="p-2">
                      <input
                        type="text"
                        name="address"
                        value={newLocation.address}
                        onChange={handleNewLocationChange}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md"
                        placeholder="新地點"
                      />
                    </td>
                    <td className="p-2">
                      <button
                        type="button"
                        onClick={handleAddLocation}
                        className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                      >
                        新增
                      </button>
                    </td>
                  </tr>
                  {formData.onBoardAddress.map((location, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="p-2">
                        {editingIndex === index ? (
                          <input
                            type="text"
                            value={location.city}
                            onChange={(e) => handleLocationChange(index, 'city', e.target.value)}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md"
                          />
                        ) : (
                          location.city
                        )}
                      </td>
                      <td className="p-2">
                        {editingIndex === index ? (
                          <input
                            type="text"
                            value={location.address}
                            onChange={(e) => handleLocationChange(index, 'address', e.target.value)}
                            className="w-full px-2 py-1 border border-gray-300 rounded-md"
                          />
                        ) : (
                          location.address
                        )}
                      </td>
                      <td className="p-2">
                        {editingIndex === index ? (
                          <button
                            type="button"
                            onClick={handleSaveLocation}
                            className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300"
                          >
                            保存
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleEditLocation(index)}
                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                          >
                            編輯
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => handleDeleteLocation(index)}
                          className="ml-2 px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
                        >
                          刪除
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex justify-center space-x-3">
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
              儲存
            </button>
            <button type="button" onClick={() => navigate('/companies')} className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
              返回
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanyForm;
