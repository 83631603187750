import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompanyById, createCompany, updateCompany } from '../../services/api';

function Profile() {
  const [formData, setFormData] = useState({
    taxIdNumber: '',
    chineseName: '',
    address: '',
    onBoardAddress: [],
  });
  const [newLocation, setNewLocation] = useState({ city: '', address: '' });
  const [editingIndex, setEditingIndex] = useState(-1);
  const id = useLocation().search.split('interviewId=')[1];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleAddLocation = () => {
    if (newLocation.city && newLocation.address) {
      setFormData(prev => ({
        ...prev,
        onBoardAddress: [...prev.onBoardAddress, newLocation]
      }));
      setNewLocation({ city: '', address: '' });
    }
  };

  const handleLocationChange = (index, field, value) => {
    const updatedLocations = formData.onBoardAddress.map((loc, i) => 
      i === index ? { ...loc, [field]: value } : loc
    );
    setFormData({ ...formData, onBoardAddress: updatedLocations });
  };

  const handleEditLocation = (index) => {
    setEditingIndex(index);
  };

  const handleSaveLocation = () => {
    setEditingIndex(-1);
  };

  const handleDeleteLocation = (index) => {
    if (window.confirm('確定要刪除嗎？')) {
      try {
        const updatedLocations = formData.onBoardAddress.filter((_, i) => i !== index);
        setFormData({ ...formData, onBoardAddress: updatedLocations });
      } catch (error) {
        console.error('刪除公司失敗:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNewLocationChange = (e) => {
    const { name, value } = e.target;
    setNewLocation(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      if (id) {
        await updateCompany(id, formData);
      } else {
        await createCompany(formData);
      }
      navigate('/companies');
    } catch (error) {
      setError('保存公司數據失敗');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    // 儲存邏輯
    // ... 儲存資料的代碼 ...

    // 儲存後導航到教育頁面
    navigate(`/application-info/education?interviewId=${id}`);
  };

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">基本資料</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <div className="overflow-x-auto">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">照片上傳</label>
                <input type="file" name="photo" className="block pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => setFormData({ ...formData, photo: e.target.result });
                    reader.readAsDataURL(file);
                  }
                }} />
                {formData.photo && <img src={formData.photo} alt="上傳的照片" className="mt-2 w-24 h-24 object-cover rounded-full" />}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">中文姓名</label>
                <input
                  type="text"
                  name="taxIdNumber"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.taxIdNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">英文姓名</label>
                <input
                  type="text"
                  name="chineseName"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.chineseName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">身分證字號/居留證</label>
                <input
                  type="text"
                  name="englishName"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.englishName}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">國籍</label>
                <input
                  type="text"
                  name="phoneNumber"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">生日</label>
                <input type="date" className="block w-full pl-3 pr-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">性別</label>
                <input type="radio" id="male" name="gender" value="male" />
                <label htmlFor="male" className='ml-1 mr-3'>男</label>
                <input type="radio" id="female" name="gender" value="female" />
                <label htmlFor="female" className='ml-1 mr-3'>女</label>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">血型</label>
                <input type="radio" id="a" name="bloodType" value="a" />
                <label htmlFor="a" className='ml-1 mr-3'>A</label>
                <input type="radio" id="ab" name="bloodType" value="ab" />
                <label htmlFor="ab" className='ml-1 mr-3'>AB</label>
                <input type="radio" id="b" name="bloodType" value="b" />
                <label htmlFor="b" className='ml-1 mr-3'>B</label>
                <input type="radio" id="o" name="bloodType" value="o" />
                <label htmlFor="o" className='ml-1 mr-3'>O</label>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">婚姻狀況</label>
                <input type="radio" id="married" name="maritalStatus" value="married" />
                <label htmlFor="married" className='ml-1 mr-3'>已婚</label>
                <input type="radio" id="unmarried" name="maritalStatus" value="unmarried" />
                <label htmlFor="unmarried" className='ml-1 mr-3'>未婚</label>
                <input type="radio" id="other" name="maritalStatus" value="other" />
                <label htmlFor="other" className='ml-1 mr-3'>其他</label>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">戶籍地址</label>
                <input
                  type="text"
                  name="permanentAddress"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.permanentAddress}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">通訊地址</label>
                <input
                  type="text"
                  name="residenceAddress"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.residenceAddress}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">E-mail Address</label>
                <input
                  type="text"
                  name="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">聯絡電話</label>
                <input
                  type="text"
                  name="telephone"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.telephone}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">聯絡電話</label>
                <input
                  type="text"
                  name="telephone2"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.telephone2}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">行動電話</label>
                <input
                  type="text"
                  name="mobile"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.mobile}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">兵役狀況</label>
                <input type="radio" id="役畢" name="military" value="役畢" />
                <label htmlFor="役畢" className='ml-1 mr-3'>役畢</label>
                <input type="radio" id="免役" name="military" value="免役" />
                <label htmlFor="免役" className='ml-1 mr-3'>免役</label>
                <input type="radio" id="待役" name="military" value="待役" />
                <label htmlFor="待役" className='ml-1 mr-3'>待役</label>
                <input type="radio" id="其他" name="military" value="其他" />
                <label htmlFor="其他" className='ml-1 mr-1'>其他：</label>
                <input type="text" name="other" 
                className="w-24 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.military_other} 
                onChange={handleInputChange} />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">退役日期</label>
                <input
                  type="text"
                  name="military_retirement_date"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.military_retirement_date}
                  onChange={handleInputChange}
                  placeholder='YYYY-MM'
                />
              </div>
              <div className='col-span-2'>
                <label className="block text-sm font-medium text-gray-700 mb-1">是否領有身心障礙者證明手冊？</label>
                <input type="radio" id="否" name="disability" value="否" />
                <label htmlFor="否" className='ml-1 mr-3'>否</label>
                <input type="radio" id="是" name="disability" value="是" />
                <label htmlFor="是" className='ml-1 mr-3'>是</label>
                （
                <input type="radio" name="other" 
                value={formData.military_other} 
                onChange={handleInputChange} />
                <label htmlFor="極重度" className='ml-1 mr-1'>極重度</label>
                <input type="radio" name="other" 
                value={formData.military_other} 
                onChange={handleInputChange} />
                <label htmlFor="重度" className='ml-1 mr-1'>重度</label>
                <input type="radio" name="other" 
                value={formData.military_other} 
                onChange={handleInputChange} />
                <label htmlFor="中度" className='ml-1 mr-1'>中度</label>
                <input type="radio" name="other" 
                value={formData.military_other} 
                onChange={handleInputChange} />
                <label htmlFor="輕度" className='ml-1 mr-1'>輕度</label>
                ）
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">希望待遇（月薪/年薪）</label>
                <input
                  type="text"
                  name="mobile"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.mobile}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">可報到日期</label>
                <input
                  type="date"
                  name="available_date"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.avaliable_date}
                  onChange={handleInputChange}
                  placeholder='YYYY/MM/DD'
                />
              </div>
            </div>
            <div className="flex justify-center space-x-3 mt-3">
              <button type="reset" className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
                清除
              </button>
              <button type="submit" onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
                儲存
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
