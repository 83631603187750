import React from 'react';

function TaxExemptionFormIncomeRecipient() {
  return (
    <table border="1" cellspacing="1" cellpadding="5" className="w-full mt-4  border border-gray-300">
      <tr>
        <td className="border border-slate-300 text-center">薪資受領人</td>
        <td className="border border-slate-300 text-center" rowSpan="2">姓名</td>
        <td className="border border-slate-300 text-center">王小明</td>
        <td className="border border-slate-300 text-center" rowSpan="2">民國出生年月日</td>
        <td className="border border-slate-300 text-center">80/01/01</td>
        <td className="border border-slate-300 text-center" rowSpan="2">國民身分證統一編號</td>
        <td className="border border-slate-300 text-center">A123123123</td>
        <td className="border border-slate-300 text-center" rowSpan="2">地址</td>
        <td className="border border-slate-300 text-center">台北市中山區</td>
      </tr>
      <tr>
        <td className="border border-slate-300 text-center">配偶</td>
        <td className="border border-slate-300 text-center">劉小芬</td>
        <td className="border border-slate-300 text-center">83/08/01</td>
        <td className="border border-slate-300 text-center">A123123123</td>
        <td className="border border-slate-300 text-center">台北市信義區</td>
      </tr>
    </table>
  )
}

export default TaxExemptionFormIncomeRecipient;  