import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch, FaUndo, FaFileExcel, FaFilePdf, FaSortUp, FaSortDown } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const API_URL = process.env.REACT_APP_API_URL;

function Roles() {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(25);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [showSearchModal, setShowSearchModal] = useState(false);

  const fetchCompanies = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    try {
      // const response = await axios.get(`${API_URL}/api/comm/v1/system/Corporations`, {
      //   // params: {
      //   //   page: page,
      //   //   limit: companiesPerPage,
      //   //   search: search
      //   // }
      // });
      const response = {
        data:{
          items:[{
              id: 1,
              name: '王小明',
              email: 'wangsmallming@gmail.com'
            },{
              id: 2,
              name: '陳小蒨',
              email: 'chenxiaochen@gmail.com'
            }]
        }
      };
      console.log('API 完整響應:', response.data);

      if (response.data && Array.isArray(response.data.items)) {
        setCompanies(response.data.items);
        setTotalCompanies(response.data.total);
      } else {
        console.error('API 響應結構不符合預期:', response.data);
        setCompanies([]);
        setTotalCompanies(0);
        setError('獲取公司數據時出現問題');
      }
    } catch (error) {
      console.error('獲取公司列表失敗:', error);
      setError('獲取公司列表時發生錯誤');
      setCompanies([]);
      setTotalCompanies(0);
    } finally {
      setLoading(false);
    }
  }, [companiesPerPage]);

  useEffect(() => {
    fetchCompanies(searchTerm, currentPage);
  }, [fetchCompanies, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchCompanies(searchTerm, 1);
  };

  const handleReset = () => {
    setSearchTerm('');
    setCurrentPage(1);
    fetchCompanies('', 1);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這家公司嗎？')) {
      try {
        await axios.delete(`${API_URL}/api/comm/v1/system/Corporation/${id}`);
        fetchCompanies(searchTerm, currentPage);
      } catch (error) {
        console.error('刪除公司失敗:', error);
      }
    }
  };

  // 計算總頁數
  const totalPages = Math.ceil(totalCompanies / companiesPerPage);

  // 改變頁面
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // 分頁按鈕渲染邏輯
  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(companies);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Companies");
    XLSX.writeFile(workbook, "公司列表.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['公司統編', '公司名稱', '公司登記地址', '開戶銀行', '報到通知提供之報到地點']],
      body: companies.map(company => [
        company.taxIdNumber,
        company.chineseName,
        company.address,
        company.bank,
        company.onBoardAddress.map(loc => `${loc.city}：${loc.address}`).join('\n')
      ]),
    });
    doc.save('公司列表.pdf');
  };

  // 新增排序功能
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = ''; // 預設狀態
      }
    }
    setSortConfig({ key, direction });
  };

  // 排序公司資料
  const sortedCompanies = () => {
    if (sortConfig.key) {
      return [...companies].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return companies; // 如果沒有排序，返回原始資料
  };

  if (loading) return <div>加載中...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">角色權限管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">角色權限項目清單</h1>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
      </div>
    </div>
  );
}

export default Roles;
