import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function OtherInfo() {
  const [formData, setFormData] = useState({
    memo: '',
  });
  const navigate = useNavigate();
  const id = useLocation().search.split('interviewId=')[1];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('保存的數據:', formData);
    navigate(`/application-info/goals?interviewId=${id}`);
  }

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">其他說明</h1>
        </div>
      </div>
      <div className="bg-white shadow-sm rounded-lg p-6 max-w-6xl">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <h3 className="block text-lg font-medium text-gray-700">其他說明</h3>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">推薦</label>
            <input
              type="radio"
              name="recommenderName"
              className="mr-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            員工
            <input
              type="radio"
              name="recommenderName"
              className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            原廠
            <input
              type="radio"
              name="recommenderName"
              className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            經銷商
            <input
              type="radio"
              name="recommenderName"
              className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            其他
            <input
              type="text"
              name="recommenderName"
              className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">推薦人姓名</label>
            <input
              type="text"
              name="recommenderName"
              className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">在本公司任職之親友</label>
            <div className="flex">
              <label className="block text-sm font-medium text-gray-700 mb-1 mr-1">姓名</label>
              <input
                type="text"
                name="recommenderName"
                className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
              <label className="block text-sm font-medium text-gray-700 mb-1 ml-1 mr-1">/關係</label>
              <input
                type="text"
                name="recommenderName"
                className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">與曾經任職公司是否發生過勞資糾紛或官司？</label>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                否
              </div>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                是，請說明
                <input
                  type="text"
                  name="recommenderName"
                  className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">是否曾經任職公司資遣或開除？</label>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                否
              </div>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                是，請說明
                <input
                  type="text"
                  name="recommenderName"
                  className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">是否曾經應徵過本公司之工作？</label>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                否
              </div>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                是，請說明
                <input
                  type="text"
                  name="recommenderName"
                  className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">是否曾有犯罪紀錄</label>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                否
              </div>
              <div>
                <input
                  type="radio"
                  name="recommenderName"
                  className="mr-1 ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
                是，請說明
                <input
                  type="text"
                  name="recommenderName"
                  className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center space-x-2">
            <button type="reset" className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100">
              清除
            </button>
            <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              儲存
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OtherInfo;
