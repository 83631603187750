import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FaSearch, FaUndo } from 'react-icons/fa';

const API_URL = process.env.REACT_APP_API_URL;

function Meetings() {
  const [meetings, setMeetings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [meetingsPerPage] = useState(25);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { interviewId } = useParams();

  const fetchMeetings = useCallback(async (search, page) => {
    setLoading(true);
    setError(null);
    setMeetings([{
      id: 'E57B270C-01A5-44D5-93FB-003D9DF9C66D',
      createdTime: '2024/7/20 17:30',
      hr_interviewer: '黃大米',
      meeting_managers: ['初試-李大華-2024/7/25 13:30', '複試-張小美-2024/7/25 14:30'],
    }]);
    setTotalMeetings(0);  
    return;
    try {
      const response = await axios.get(`${API_URL}/api/comm/v1/system/Meetings`, {
        params: {
          interviewId: interviewId,
        }
      });
      console.log('API 完整響應:', response.data);

      if (response.data && Array.isArray(response.data.items)) {
        setMeetings(response.data.items);
        setTotalMeetings(response.data.total);
      } else {
        console.error('API 響應結構不符合預期:', response.data);
        setMeetings([]);
        setTotalMeetings(0);
        setError('獲取面試數據時出現問題');
      }
    } catch (error) {
      console.error('獲取面試列表失敗:', error);
      setError('獲取面試列表時發生錯誤');
      setMeetings([]);
      setTotalMeetings(0);
    } finally {
      setLoading(false);
    }
  }, [meetingsPerPage]);

  useEffect(() => {
    fetchMeetings(searchTerm, currentPage);
  }, [fetchMeetings, currentPage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchMeetings(searchTerm, 1);
  };

  const handleReset = () => {
    setSearchTerm('');
    setCurrentPage(1);
    fetchMeetings('', 1);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這家公司嗎？')) {
      try {
        await axios.delete(`${API_URL}/api/comm/v1/system/Corporation/${id}`);
        fetchMeetings(searchTerm, currentPage);
      } catch (error) {
        console.error('刪除面試失敗:', error);
      }
    }
  };

  // 計算總頁數
  const totalPages = Math.ceil(totalMeetings / meetingsPerPage);

  // 改變頁面
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // 分頁按鈕渲染邏輯
  const renderPaginationButtons = () => {
    const maxButtons = 5;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (currentPage + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxButtons / 2);
        endPage = currentPage + Math.floor(maxButtons / 2);
      }
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => paginate(1)} className="mx-1 px-3 py-1 border rounded">1</button>
            {startPage > 2 && <span className="mx-1">...</span>}
          </>
        )}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(number => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="mx-1">...</span>}
            <button onClick={() => paginate(totalPages)} className="mx-1 px-3 py-1 border rounded">{totalPages}</button>
          </>
        )}
      </>
    );
  };

  // if (loading) return <div>加載中...</div>;
  // if (error) return <div>{error}</div>;

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">招募管理</span>
          <h1 className="text-2xl font-semibold text-gray-800">面談列表</h1>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex justify-between items-center">
          <div className='flex w-3/4'>
            <div className='flex-1'>
              <label className="text-gray-400">姓名</label>
              <div>王小明</div>
            </div>
            <div className='flex-1'>
              <label className="text-gray-400">Email</label>
              <div>test@gmail.com</div>
            </div>
            <div className='flex-1'>
              <label className="text-gray-400">應徵部門</label>
              <div>零壹科技-資訊中心</div>
            </div>
            <div className='flex-1'>
              <label className="text-gray-400">應徵職務</label>
              <div>工程師</div>
            </div>
          </div>
          <div className="flex justify-end">
            <Link to="/interviews" className="mr-1 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
              返回面試
            </Link>
            <Link to="/meetings/new" className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
              新增面談
            </Link>
          </div>
        </div>
        <div className="overflow-x-auto mt-4">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-100 text-center">
                <th className="p-3 font-semibold text-sm text-gray-600">通知時間</th>
                <th className="p-3 font-semibold text-sm text-gray-600">HR面談者</th>
                <th className="p-3 font-semibold text-sm text-gray-600">面談主管</th>
                <th className="p-3 font-semibold text-sm text-gray-600">管理</th>
              </tr>
            </thead>
            <tbody>
              {meetings.map((meeting, index) => (
                <tr key={meeting.id} className={index % 2 === 0 ? 'bg-white text-center' : 'bg-gray-50 text-center'}>
                  <td className="p-3 text-sm text-gray-700">{meeting.createdTime}</td>
                  <td className="p-3 text-sm text-gray-700">{meeting.hr_interviewer}</td>
                  <td className="p-3 text-sm text-gray-700">
                    {meeting.meeting_managers.map((meeting_manager, index) => (
                      <div key={index}>
                        {meeting_manager}
                      </div>
                    ))}
                  </td>
                  <td className="p-3 text-sm">
                    <div>
                      <a href={`/meetings/${meeting.id}`} className="text-blue-500 mr-2 hover:underline">
                        詳細
                      </a>
                      <a href={`/meetings/edit/${meeting.id}`} className="text-blue-500 mr-2 hover:underline">
                        编辑
                      </a>
                    </div>
                    <div>
                      <a href={`/meetings/hr-score/${meeting.id}`} className="text-blue-500 mr-2 hover:underline">
                        HR評分
                      </a>
                      <a href={`/meetings/manager-score/${meeting.id}`} className="text-blue-500 mr-2 hover:underline">
                        主管評分
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {totalPages > 1 && (
          <div className="mt-6 flex justify-center items-center">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              ←
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="mx-1 px-3 py-1 border rounded text-gray-600 disabled:opacity-50 hover:bg-gray-100 transition duration-300"
            >
              →
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Meetings;
