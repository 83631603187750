import React, { useState, useEffect } from 'react';
import Header from './Header';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';

function Layout() {
  const id = useLocation().search.split('interviewId=')[1];
  const logo = require('../../assets/zerone-logo.png');
  const isDesktop = window.innerWidth > 1024;
  const [isSidebarOpen, setIsSidebarOpen] = useState(isDesktop);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // 新增狀態來控制各部分的展開/收合
  const [expandedSections, setExpandedSections] = useState({
    systemManagement: true,
    recruitmentManagement: true,
    hireManagement: true,
    onboardingManagement: true,
    memberManagement: true
  });

  useEffect(() => {
    const checkScreenSize = () => {
      const isSmall = window.innerWidth < 1024;
      setIsSmallScreen(isSmall);
      if (!isSmall) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isSmallScreen) {
      setIsSidebarOpen(false);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path);
  };

  const menuItemClass = (path) => `
    w-28
    ${isActive(path) 
      ? 'text-blue-600' 
      : 'text-gray-700'}
  `;

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      {/* 左側選單 */}
      <nav className={`
        ${isSidebarOpen ? 'translate-x-0 lg:relative lg:transform-none' : '-translate-x-full'}
        bg-white shadow-lg transition-transform duration-300 ease-in-out
        fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto
      `}>
        <div className="p-4">
          <h1 onClick={() => handleNavigation('/recruiter')} className="text-center font-bold text-gray-800 cursor-pointer">零壹科技錄取管理
          </h1>
        </div>
        <ul className="ml-12">
          <li className="mt-3">
            <div className="flex">
              <Link to={`/application-info/profile`} className={menuItemClass('/application-info/profile')}>
                人事資料表
              </Link>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/recruiter/tax-exemption-spouse-form`} className={menuItemClass('/recruiter/tax-exemption')}>
                員工薪資所得受領人免稅額申報表
              </Link>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/recruiter/employee-family-health-insurance-enrollment-record`} className={menuItemClass('/recruiter/employee-family-health-insurance-enrollment-record')}>
                員工眷屬健保加退保紀錄表
              </Link>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/recruiter/employee-retirement-fund-self-deduction-inquiry-form`} className={menuItemClass('/recruiter/employee-retirement-fund-self-deduction-inquiry-form')}>
                勞工退休金制度自提率徵詢表
              </Link>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/recruiter/engineer-certificate-details`} className={menuItemClass('/recruiter/engineer-certificate')}>
                工程師證明文件
              </Link>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/recruiter/self-introduction`} className={menuItemClass('/recruiter/self-introduction')}>
                自我介紹
              </Link>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/recruiter/set-english-name`} className={menuItemClass('/recruiter/set-english-name')}>
                設定英文姓名
              </Link>
            </div>
          </li>
          <li className="mt-3">
            <div className="flex">
              <Link to={`/recruiter/doc-upload`} className={menuItemClass('/recruiter/doc-upload')}>
                各式文件上傳
              </Link>
            </div>
          </li>
        </ul>
      </nav>

      {/* 遮罩層 */}
      {isSidebarOpen && isSmallScreen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden" 
          onClick={toggleSidebar}
        ></div>
      )}

      {/* 主要內容區域 */}
      <main className={`flex-1 flex flex-col overflow-hidden transition-all duration-300`}>
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        <div className="flex-1 overflow-auto p-6">
          <Outlet />
        </div>
        <div className="flex justify-between items-end p-4 px-10">
          <div className='text-gray-400 text-sm font-bold'>
            COMMIT TO EXCELLENCE
          </div>
          <div>
            <img src={logo} alt="零壹科技eHRM" width={100} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default Layout;
