import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TaxExemptionFormMenu from './TaxExemptionFormMenu';
import TaxExemptionFormIncomeRecipient from './TaxExemptionFormIncomeRecipient';

function TaxExemptionImmediateRelativesForm() {
  const [persons, setPersons] = useState([
    { id: 1, name: '劉小芬', title: '子', birthDate: '83/08/01', idNumber: 'A123456789', address: '台北市信義區', condition: '1' }
  ]);
  const [newTemplate, setNewTemplate] = useState('');
  const [templates, setTemplates] = useState([
    { id: 1, name: '錄取通知模板', isEditing: false, originalName: '錄取通知模板' }
  ]);
  const navigate = useNavigate();

  const handleAdd = () => {
    if (newTemplate.trim()) {
      setTemplates([...templates, { id: Date.now(), name: newTemplate, isEditing: false, originalName: newTemplate }]);
      setNewTemplate('');
    }
  };

  const handleEdit = (id) => {
    setPersons(persons.map(person =>
      person.id === id ? { ...person, isEditing: true } : person
    ));
  };

  const handleSave = (id) => {
    setPersons(persons.map(person =>
      person.id === id ? { ...person, isEditing: false, originalName: person.name } : person
    ));
  };

  const handleCancel = (id) => {
    setPersons(persons.map(person =>
      person.id === id ? { ...person, isEditing: false, name: person.originalName } : person
    ));
  };

  const handleDelete = (id) => {
    const personToDelete = persons.find(person => person.id === id);
    if (window.confirm(`確定要刪除「${personToDelete.name}」嗎？`)) {
      setPersons(persons.filter(person => person.id !== id));
    }
  };

  return (
    <div className="mx-auto max-w-6xl">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">員工薪資所得受領人免稅額申報表</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <TaxExemptionFormMenu activeTab="immediateRelatives" />
        <div>
          <TaxExemptionFormIncomeRecipient />
          <div className="mt-4">
            一、依照所得稅法第十七條規定，納稅義務人及其配偶之直系尊親屬合於下列條件之一者，每年每人得減除其扶養親屬免稅額。
            <ul>
              <li>(1)年滿六十歲者；</li>
              <li>(2)未滿六十歲者，但無謀生能力受納稅義務人扶養者。</li>
            </ul>
          </div>
          <table className="w-full border-collapse border border-gray-300 mt-4">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">姓名</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">稱謂</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">民國出生年月日</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">身分證字號統一編號</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">現在地址</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">符合之條件</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">編輯</th>
              </tr>
            </thead>        
            <tbody>
              <tr>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="姓名"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="稱謂"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="民國出生年月日"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="身分證字號統一編號"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    className="w-full p-2 border rounded"
                    placeholder="現在地址"
                  />
                </td>
                <td className="border px-4 py-2">
                  <select
                    className="w-full p-2 border rounded"
                  >
                    <option value="">符合之條件</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                </td>
                <td className="border px-4 py-2">
                  <button
                    type="button"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={() => handleAdd()}
                  >
                    新增
                  </button>
                </td>
              </tr>
              {persons.map(person => (
                <tr key={person.id}>
                  <td className="border px-4 py-2">
                    {person.editing ? (
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={person.name}
                        onChange={(e) => handleEdit(person.id, e.target.value)}
                      />
                    ) : (
                      <span>{person.name}</span>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {person.editing ? (
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={person.title}
                        onChange={(e) => handleEdit(person.id, e.target.value)}
                      />
                    ) : (
                      <span>{person.title}</span>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {person.editing ? (
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={person.birthDate}
                        onChange={(e) => handleEdit(person.id, e.target.value)}
                      />
                    ) : (
                      <span>{person.birthDate}</span>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {person.editing ? (
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={person.idNumber}
                        onChange={(e) => handleEdit(person.id, e.target.value)}
                      />
                    ) : (
                      <span>{person.idNumber}</span>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {person.editing ? (
                      <input
                        type="text"
                        className="w-full p-2 border rounded"
                        value={person.address}
                        onChange={(e) => handleEdit(person.id, e.target.value)}
                      />
                    ) : (
                      <span>{person.address}</span>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {person.editing ? (
                      <select
                        className="w-full p-2 border rounded"
                        value={person.condition}
                        onChange={(e) => handleEdit(person.id, e.target.value)}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                    ) : (
                      <span>{person.condition}</span>
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {person.editing ? (
                      <>
                        <button
                          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
                          onClick={() => handleSave(person.id)}
                        >
                          儲存
                        </button>
                        <button
                          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                          onClick={() => handleCancel(person.id)}
                        >
                          取消
                        </button>
                      </>
                    ) : (
                      <button
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        onClick={() => handleEdit(person.id)}
                      >
                        編輯
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 text-right">
          本人及本人之配偶合於上列條件之直系尊親屬有：1 人 
        </div>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        <button type="button" onClick={() => navigate(`/recruiter/tax-exemption-spouse-form`)} className="px-4 py-2 border rounded-md text-gray-600 bg-blue-500 text-white hover:bg-blue-600">
          上一步
        </button>
        <button type="button" onClick={() => navigate(`/recruiter/tax-exemption-children-form`)} className="px-4 py-2 border rounded-md text-gray-600 bg-blue-500 text-white hover:bg-blue-600">
          下一步
        </button>
        <button type="button" onClick={() => navigate(`/recruiter`)} className="px-4 py-2 border rounded-md text-gray-600 bg-blue-500 text-white hover:bg-blue-600">
          儲存並返回
        </button>
      </div>
    </div>
  );
}

export default TaxExemptionImmediateRelativesForm;
