import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Education() {
  const id = useLocation().search.split('interviewId=')[1];
  const navigate = useNavigate();
  const [newEducation, setNewEducation] = useState('');
  const [editEducation, setEditEducation] = useState('');
  const [educations, setEducations] = useState([{
    education: '研究所', 
    school: '國立臺灣大學', 
    major: '資訊工程學系', 
    graduationStatus: '畢業', 
    startDate: '2018/09', 
    endDate: '2022/06'}]);
  const [editIndex, setEditIndex] = useState(null);

  const handleSave = () => {
    // 儲存邏輯
    // ... 儲存資料的代碼 ...

    // 儲存後導航到教育頁面
    navigate(`/application-info/experience?interviewId=${id}`);
  };

  const handleAdd = () => {
    if (newEducation.education && newEducation.school && newEducation.major && newEducation.graduationStatus && newEducation.startDate && newEducation.endDate) {
      setEducations([...educations, newEducation]);
      setNewEducation('');
    }
  };

  const handleEditSave = () => {
    if (editIndex !== null) {
      const updatedEducations = [...educations];
      updatedEducations[editIndex] = editEducation;
      setEducations(updatedEducations);
      setEditIndex(null);
    } else {
      setEducations([...educations, editEducation]);
    }
    setEditEducation('');
  };

  const handleAddCancel = () => {
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setEditEducation(educations[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (window.confirm('您確定要刪除這個學歷嗎？')) {
      const updatedEducations = educations.filter((_, i) => i !== index);
      setEducations(updatedEducations);
    }
  };

  return (
    <div id="application-info" className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400">應徵者輸入應徵資料</span>
          <h1 className="text-2xl font-semibold text-gray-800">教育程度</h1>
        </div>
      </div>
      <div className="flex">
        <div>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-300">
              <thead className="bg-gray-50">
                <tr className='text-center'>
                  <th colspan="2" className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">學校</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">主修科系</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">畢肄業</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">起迄時間</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider">管理</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-4 py-2">
                    <select
                      className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={newEducation.education}
                      onChange={(e) => setNewEducation(e.target.value)}
                    >
                      <option value="">請選擇學歷</option>
                      <option value="研究所">研究所</option>
                      <option value="大學">大學</option>
                      <option value="高中職">高中職</option>
                      <option value="專科">專科</option>
                      <option value="其他">其他</option>
                    </select>
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={newEducation.school}
                      onChange={(e) => setNewEducation(e.target.value)}
                      placeholder='學校名稱'
                    />
                  </td>
                  <td className="px-4 py-2">
                    <input
                      type="text"
                      className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      value={newEducation.major}
                      onChange={(e) => setNewEducation(e.target.value)}
                      placeholder='主修科系'
                    />
                  </td>
                  <td className="px-4 py-2">
                    <div className="flex flex-col">
                      <div className="flex flex-row">
                        <div className="flex items-center mr-4">
                          <input
                            id="graduated"
                            name="graduationStatus"
                            type="radio"
                            value="畢業"
                            className="form-radio"
                            checked={newEducation.graduationStatus === '畢業'}
                            onChange={(e) => setNewEducation(e.target.value)}
                          />
                          <label htmlFor="graduated" className="ml-3 block text-sm font-medium text-gray-700">
                            畢業
                          </label>
                        </div>
                        <div className="flex items-center mr-4">
                          <input
                            id="notGraduated"
                            name="graduationStatus"
                            type="radio"
                            value="肄業"
                            className="form-radio"
                            checked={newEducation.graduationStatus === '肄業'}
                            onChange={(e) => setNewEducation(e.target.value)}
                          />
                          <label htmlFor="notGraduated" className="ml-3 block text-sm font-medium text-gray-700">
                            肄業
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="studying"
                            name="graduationStatus"
                            type="radio"
                            value="就學中"
                            className="form-radio"
                            checked={newEducation.graduationStatus === '就學中'}
                            onChange={(e) => setNewEducation(e.target.value)}
                          />
                          <label htmlFor="studying" className="ml-3 block text-sm font-medium text-gray-700">
                            就學中
                          </label>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2">
                    <div className="flex">
                      <input
                        id="startDate"
                        name="startDate"
                        type="text"
                        className="w-24 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="YYYY/MM"
                        value={newEducation.startDate}
                        onChange={(e) => setNewEducation(e.target.value)}
                      />
                      ~
                      <input
                        id="endDate"
                        name="endDate"
                        type="text"
                        className="w-24 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="YYYY/MM"
                        value={newEducation.endDate}
                        onChange={(e) => setNewEducation(e.target.value)}
                      />
                    </div>
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={handleAdd}
                      className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      新增
                    </button>
                  </td>
                </tr>
                {educations.map((education, index) => (
                  <tr key={index}>
                    <td className="px-4 py-2">
                      {editIndex === index ? (
                        <select
                        className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        value={editEducation.education}
                        onChange={(e) => setEditEducation(e.target.value)}
                      >
                        <option value="">請選擇學歷</option>
                        <option value="研究所">研究所</option>
                        <option value="大學">大學</option>
                        <option value="高中職">高中職</option>
                        <option value="專科">專科</option>
                        <option value="其他">其他</option>
                      </select>
                      ) : (
                        education.education
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={editEducation.school}
                          onChange={(e) => setEditEducation(e.target.value)}
                        />
                      ) : (
                        education.school
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {editIndex === index ? (
                        <input
                          type="text"
                          className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          value={editEducation.major}
                          onChange={(e) => setEditEducation(e.target.value)}
                        />
                      ) : (
                        education.major
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {editIndex === index ? (
                        <div className="flex flex-row">
                          <div className="flex items-center mr-4">
                            <input
                              id="graduated"
                              name="graduationStatus"
                              type="radio"
                              value="畢業"
                              className="form-radio"
                              checked={editEducation.graduationStatus === '畢業'}
                              onChange={(e) => setEditEducation(e.target.value)}
                            />
                            <label htmlFor="graduated" className="ml-3 block text-sm font-medium text-gray-700">
                              畢業
                            </label>
                          </div>
                          <div className="flex items-center mr-4">
                            <input
                              id="notGraduated"
                              name="graduationStatus"
                              type="radio"
                              value="肄業"
                              className="form-radio"
                              checked={editEducation.graduationStatus === '肄業'}
                              onChange={(e) => setEditEducation(e.target.value)}
                            />
                            <label htmlFor="notGraduated" className="ml-3 block text-sm font-medium text-gray-700">
                              肄業
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="studying"
                              name="graduationStatus"
                              type="radio"
                              value="就學中"
                              className="form-radio"
                              checked={editEducation.graduationStatus === '就學中'}
                              onChange={(e) => setEditEducation(e.target.value)}
                            />
                            <label htmlFor="studying" className="ml-3 block text-sm font-medium text-gray-700">
                              就學中
                            </label>
                          </div>
                        </div>
                      ) : (
                        education.graduationStatus
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {editIndex === index ? (
                        <div className='flex'>
                          <div>
                            <input
                              id="startDate"
                              name="startDate"
                              type="text"
                              className="w-24 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="YYYY/MM"
                              value={editEducation.startDate}
                              onChange={(e) => setEditEducation(e.target.value)}
                            />
                            ~
                            <input
                              id="endDate"
                              name="endDate"
                              type="text"
                              className="w-24 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                              placeholder="YYYY/MM"
                              value={editEducation.endDate}
                              onChange={(e) => setEditEducation(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>{education.startDate} ~ {education.endDate}</div>
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {editIndex === index ? (
                        <div>
                          <button
                            onClick={handleEditSave}
                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            儲存
                          </button>
                          <button
                            onClick={handleAddCancel}
                            className="ml-2 px-3 py-1 rounded-md bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            取消
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={() => handleEdit(index)}
                            className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
                          >
                            編輯
                          </button>
                          <button
                            onClick={() => handleDelete(index)}
                            className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          >
                            刪除
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-center space-x-3 mt-3">
              <button type="reset" className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition duration-300">
                清除
              </button>
              <button type="submit" onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
                儲存
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;
