import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SelfIntroduction() {
  const navigate = useNavigate();

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <div>
          <span className="text-gray-400"></span>
          <h1 className="text-2xl font-semibold text-gray-800">自我介紹</h1>
        </div>
      </div>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="overflow-x-auto">
          <div>
            照片上傳：
            <input type="file" className="mt-2" />
            <br/>
            自我介紹(200字以內，簡單介紹之前工作經歷及未來期許)：
            <textarea className='w-full h-48 mt-2 px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'></textarea>
          </div>
          <div className="text-right mt-4">
            <button
              onClick={() => navigate('/recruiter')}
              className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelfIntroduction;
