import React from 'react';

function Home() {
  const logo = require('../../assets/zerone-logo.png');

  return (
    <div className='flex justify-center items-center h-full'>
      <img src={logo} alt="零壹科技eHRM" width={300} />
    </div>
  );
}

export default Home;
