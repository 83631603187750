import React from 'react';
import { Link } from 'react-router-dom';
function TaxExemptionFormMenu({ activeTab }) {
  const active = 'border-b-2 border-blue-600 text-blue-600';

  return (
    <div className="w-full flex space-x-4 border-b-2 border-gray-100">
      <Link to={`/recruiter/tax-exemption-spouse-form`} className={`w-1/6 text-center px-4 py-2 ${activeTab === 'spouse' ? active : ''}`}>
        配偶
      </Link> 
      <Link to={`/recruiter/tax-exemption-immediate-relatives-form`} className={`w-1/6 text-center px-4 py-2 ${activeTab === 'immediateRelatives' ? active : ''}`}>
        直系尊親屬
      </Link>
      <Link to={`/recruiter/tax-exemption-children-form`} className={`w-1/6 text-center px-4 py-2 ${activeTab === 'children' ? active : ''}`}>
        子女
      </Link>
      <Link to={`/recruiter/tax-exemption-siblings-form`} className={`w-1/6 text-center px-4 py-2 ${activeTab === 'siblings' ? active : ''}`}>
        同胞兄弟姐妹
      </Link>
      <Link to={`/recruiter/tax-exemption-other-relatives-form`} className={`w-1/6 text-center px-4 py-2 ${activeTab === 'otherRelatives' ? active : ''}`}>
        其他家/親屬
      </Link>
      <Link to={`/recruiter/tax-exemption-summary-form`} className={`w-1/6 text-center px-4 py-2 ${activeTab === 'summary' ? active : ''}`}>
        總覽
      </Link>
    </div>
  )
}

export default TaxExemptionFormMenu;  